import React from "react";
import {Field, reduxForm} from "redux-form";
import style from "./orderForm.module.css";
import {renderTextField} from "../../../../Objects/FormControls/FormControls";
import {emailValid, required} from "../../../../Objects/FormControls/Validators";

const FormOrder = ({handleSubmit}) => {
    return (
        <form className={style.wrapper} onSubmit={handleSubmit}>
            <div><Field component={renderTextField} variant="outlined" type={"text"} label="Ваше имя" placeholder={"Ваше имя"} name={"name"} validate={required}/></div>
            <div><Field component={renderTextField} variant="outlined" type={"phone"} label="Ваш телефон" placeholder={"Ваш телефон"} name={"phone"} validate={required}/></div>
            <div><Field component={renderTextField} variant="outlined" type={"email"} label="Email" placeholder={"Email"} name={"email"} validate={emailValid}/></div>
            <div></div>
            <div>
                <button className={style.button}>
                    Оформить заказ
                </button>
            </div>
        </form>
    )
};

const ReduxFormOrder = reduxForm({
    form: 'orderForm'
})(FormOrder);


const OrderForm = (props) => {

    const realCartProducts = props.cart.map(product => product.id)
    const readyValues = {products: realCartProducts}

    const onSubmit = (formData) => {
        props.sendOrder(formData)
        props.handleClick()
    }

    return(
        <>
            <ReduxFormOrder initialValues={readyValues} onSubmit={onSubmit} />
        </>
    )
}

export default OrderForm
