import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import style from "./dialogModal.module.css";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import ChipElement from "./ChipElement";

const StyledBadge = withStyles((theme) => ({
    badge: {
        left: 13,
        top: -3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: '#D90015'
    },
}))(Badge);

const DialogModal = (props) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [outValue, setOutValue] = React.useState([])

    const addToFilter = (id) => {
        if (outValue.includes(id)) setOutValue(outValue.filter(value => value !== id))
        else outValue.push(id)
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{props.filterTitle}</DialogTitle>
                <DialogContent className={style.wrapper}>
                    {
                        props.filter.length ?
                                props.filter.map((filterItem, index) =>
                                    <span key={index} className={style.items}>
                                        <StyledBadge badgeContent={filterItem.count} color="secondary"
                                                     max={9999}
                                                     anchorOrigin={{
                                                         vertical: 'top',
                                                         horizontal: 'left',
                                                     }}>
                                            <ChipElement filterItem={filterItem} addToFilter={addToFilter}/>
                                        </StyledBadge>
                                    </span>

                                )
                            : <></>
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.chose(outValue)} color="primary">
                        выбрать
                    </Button>
                    <Button autoFocus onClick={() => props.exclude(outValue)} color="secondary">
                        исключить
                    </Button>
                    <Button autoFocus onClick={props.handleClose} color="primary">
                        закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DialogModal
