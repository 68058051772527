import React from "react";
import style from "./header.module.css";
import TopMenu from "./TopMenu";
import TopBar from "./TopBar";

const Header = () => {
    return (
        <div className={style.wrapper}>
            <TopMenu className={style.topMenu} />
            <TopBar className={style.topBar} />
        </div>
    )
}

export default Header;
