import React, {useEffect} from "react";
import {Switch, Route, Redirect} from "react-router-dom";
import Catalog from "./Catalog";
import ProductPage from "./ProductPage";
import Contacts from "./Content/Contacts";
import Tender from "./Content/Tender";
import Delivery from "./Content/Delivery";
import OptoviePostavki from "./Content/OptoviePostavki";
import {connect} from "react-redux";
import {loadOpt} from "../../../Redux/FrontReducers/optReducer";
import OptCategory from "./Content/OptoviePostavki/OptCategory";
import Cart from "./Cart";
import {getCart} from "../../../Redux/FrontReducers/CatalogReducers/cartReducer";


const Main = (props) => {

    useEffect(() => {
        props.loadOpt()
        props.getCart()
    }, [props.pages.length])

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/catalog" />
            </Route>
            <Route path={'/catalog'} exact component={Catalog}/>
            <Route path={'/catalog/product/:productId'} exact component={ProductPage}/>
            <Route path={'/optovye-postavki'} exact component={OptoviePostavki}/>
            <Route path={'/tender'} exact component={Tender}/>
            <Route path={'/delivery'} exact component={Delivery}/>
            <Route path={'/contacts'} exact component={Contacts}/>
            <Route path={'/cart'} exact component={Cart}/>
            {
                props.pages.map((page,index) => <Route key={index} path={'/'+page.slug} exact render={() => <OptCategory page={page} />}/>)
            }
        </Switch>
    )
}

let mapStateToProps = (state) => {
    return {
        pages: state.opt.page,
        isFetching: state.opt.isFetching
    }
}

export default connect(mapStateToProps,{loadOpt,getCart})(Main);
