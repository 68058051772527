import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import React from "react";


const ChipElement = (props) => {

    const [active, setActive] = React.useState(false)

    const onClickElement = (id) => {
        props.addToFilter(id)
        setActive(!active)
    }

    return (
        <Chip label={props.filterItem.title}
              size="small"
              color={active ? "primary" : "default"}
              avatar={props.filterItem.image ? <Avatar alt={props.filterItem.title}
                                                 src={props.filterItem.image && "http://to4ka.kz" + props.filterItem.image}/> :
                  <Avatar>{props.filterItem.title}</Avatar>}
            /*onDelete={() => deleteVendor(filterItem.id)}*/
              onClick={() => onClickElement(props.filterItem.id)}
              variant={"outlined"}/>
    )
}

export default ChipElement
