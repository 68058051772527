import {Link} from "react-router-dom";
import React from "react";
import CategoryLinks from "../CategoryLinks";


const GeneralMenuItems = () => {
    return (
        <>
            <CategoryLinks>
                        <span>
                            каталог
                        </span>
            </CategoryLinks>
            {/*<Link to={'/about'}>
                        <span>
                            о нас
                        </span>
                    </Link>*/}
            <Link to={'/tender'}>
                        <span>
                            для тендера
                        </span>
            </Link>
            <Link to={'/optovye-postavki'}>
                        <span>
                            оптовые поставки
                        </span>
            </Link>
            <Link to={'/delivery'}>
                        <span>
                            доставка
                        </span>
            </Link>
            <Link to={'/contacts'}>
                        <span>
                            контакты
                        </span>
            </Link>
        </>
    )
}

export default GeneralMenuItems
