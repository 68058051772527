import React from "react";
import style from "./card.module.css";
import CategoryLinks from "../../../../Objects/CategoryLinks";

const Card = (props) => {

    return (
        <div className={props.productGrid.show ? style.wrapperMin : style.wrapper}>
            <div className={style.cardContent}>
                <h3 className={style.cardTitle}>{props.catalogElement.title}</h3>
                <img className={style.cardImage} src={props.catalogElement.image && `http://to4ka.kz${props.catalogElement.image}`} />
                <ul className={style.cardList}>
                    {
                        props.catalogElement.elements.length
                            ?
                            props.catalogElement.elements.map((category,index) =>
                                <li key={index}>
                                    <CategoryLinks showProductGrid={props.showProductGrid}
                                                   categoryId={category.categoryId}
                                                   vendorId={category.vendorId}>
                                        {category.title} ({category.count})
                                    </CategoryLinks>
                                </li>
                            )
                            : <></>
                    }
                </ul>
            </div>
        </div>
    );
}

{
    /*
    * <Link key={index} to={`/catalog${category.categoryId ? '/category/'+category.categoryId : ''}${category.vendorId ? '/vendor/'+category.vendorId : ''}`}>
                                    <li>{category.title} ({category.count})</li>
                                </Link>
    * */
}

export default Card;
