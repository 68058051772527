import React, {useEffect} from "react";
import style from "./card.module.css";
import Moment from "react-moment";
import 'moment/locale/ru';
import CategoryLinks from "../../../../Objects/CategoryLinks";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';


const Card = (props) => {

    const location = { pathname: `catalog/product/${props.product.id}` }

    const [realCart, setRealCart] = React.useState([]);

    useEffect(() => {
        setRealCart(props.cart.map(product => product.id))
    }, [props.cart])

    const sizeTitle = 50
    const sizeArt = 50
    const sizeDescription = 120

    let productImage = props.product.images.map( (image,index) => { if (index === 0) return image.image }).join('')
    let price = props.product.store.filter( store => store.price === Math.min.apply(null,props.product.store.map( store => store.price)))[0].storePrice

    return (
        <div className={realCart.includes(props.product.id) ? style.wrapper+' '+style.wrapper2 : style.wrapper}>
            {/*<CategoryLinks productId={props.product.id}>*/}
            <div className={style.cardContent}>
                <CategoryLinks productId={props.product.id}>
                    <h3 className={style.cardTitle}>
                        {
                            props.product.title &&
                            props.product.title.length > sizeTitle
                                ? props.product.title.slice(0, sizeTitle) + ' ...'
                                : props.product.title
                        }
                    </h3>
                </CategoryLinks>
                <span className={style.cardArt}>
                    {
                        props.product.article &&
                        <span>Артикул:
                            {
                                props.product.article.length > sizeArt
                                    ? props.product.article.slice(0, sizeArt) + ' ...'
                                    : props.product.article
                            }
                        </span>
                    }
                </span>

                <CategoryLinks productId={props.product.id}>
                    <div className={style.cardImage}>
                        <img src={productImage ? productImage : 'http://to4ka.kz'+props.categoryImg} />
                    </div>
                </CategoryLinks>

                <span className={style.cardPrice}>{price}</span>
                <span className={style.cardDate}>
                    Код товара: {props.product.code}
                    {/*<Moment locale="ru" format="DD MMMM YYYY" date={props.product.store.date}/>*/}
                </span>

                <div className={style.cardButton}>
                {
                    realCart.includes(props.product.id)
                        ?
                        <button className={style.buttonOff}
                                onClick={() => props.deleteProductFromCart(props.product.id)}>
                            Удалить из корзины
                        </button>
                        :
                        <button onClick={() => props.addProductToCart(props.product.id,props.product.title,productImage)}>
                            В корзину
                        </button>
                }
                </div>

                <a href={'https://wa.me/77074075730?text=Хочу заказать: http://to4ka.kz/catalog/product/'+props.product.id} target={'blank'}>
                    <div className={style.cardButtonWhatsapp}>
                        <WhatsAppIcon /> быстрый заказ
                    </div>
                </a>

                <span className={style.cardDescription}>
                    {
                        props.product.description &&
                        props.product.description.length > sizeDescription
                            ? props.product.description.slice(0, sizeDescription) + ' ...'
                            : props.product.description
                    }
                </span>

            </div>

        </div>
    );
}

export default Card;
