import {applyMiddleware, combineReducers, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import { reducer as formReducer } from "redux-form";
import autoTypeContactsReducer from "./RequestReducers/AutoTypeContactsReducer";
import customerContactReducer from "./RequestReducers/CustomerContactReducer";
import customerDemandsReducer from "./RequestReducers/CustomerDemandsReducer";
import catalogPageReducer from "./FrontReducers/CatalogReducers/catalogPageReducer";
import productsGridReducer from "./FrontReducers/CatalogReducers/productsGridReducer";
import productPageReducer from "./FrontReducers/CatalogReducers/productPageReducer";
import filtersReducer from "./FrontReducers/CatalogReducers/filtersReducer";
import optReducer from "./FrontReducers/optReducer";
import cartReducer from "./FrontReducers/CatalogReducers/cartReducer";

let reducers = combineReducers({
    autoTypes: autoTypeContactsReducer,
    newContact: customerContactReducer,
    customerDemands: customerDemandsReducer,
    catalogPage: catalogPageReducer,
    productsGrid: productsGridReducer,
    productPage: productPageReducer,
    filters: filtersReducer,
    opt: optReducer,
    cart: cartReducer,
    form: formReducer
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

window.store = store;

export default store;
