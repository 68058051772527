import * as axios from "axios";


const instance = axios.create({
    withCredentials: false,
    baseURL: 'http://to4ka.kz/api/'
});

const fileLoader = {headers: {"Content-Type": "multipart/form-data"}};

export const CustomerInfoAPI = {
    checkContactData(inputData) {
        const formData = new FormData();
        formData.append("q", inputData);
        return instance.post(`mrm/member/field/`, formData)
    },
    addOrUpdateContact(inputData,kindContact) {
        console.log(kindContact[0])
        const formData = new FormData();
        formData.append("main", inputData);
        if (kindContact[0]){
            formData.append("kindContact", kindContact[0]);
            if (kindContact[1]){
                formData.append("kindContact", kindContact[1]);
                if (kindContact[2]){
                    formData.append("kindContact", kindContact[2]);
                    if (kindContact[3]){
                        formData.append("kindContact", kindContact[3]);
                        if (kindContact[4]){
                            formData.append("kindContact", kindContact[4]);
                        }
                    }
                }
            }
        }

        return instance.post(`mrm/member/search/add/`, formData)
    },
    updateContact(inputData,kindContact,customerId) {
        const formData = new FormData();
        if (kindContact){
            console.log(kindContact)
        }
        formData.append("main", inputData);
        if (kindContact[0]){
            formData.append("kindContact", kindContact[0]);
            if (kindContact[1]){
                formData.append("kindContact", kindContact[1]);
                if (kindContact[2]){
                    formData.append("kindContact", kindContact[2]);
                    if (kindContact[3]){
                        formData.append("kindContact", kindContact[3]);
                        if (kindContact[4]){
                            formData.append("kindContact", kindContact[4]);
                        }
                    }
                }
            }
        }
        return instance.post(`mrm/member/${customerId}/`, formData)
    },
    updateContact2(inputData,typeContact,customerId) {
        const formData = new FormData();
        formData.append("main", inputData);
        formData.append("kindContact", typeContact);
        return instance.post(`mrm/member/${customerId}/`, formData)
    },
    getContactInfo(customerId) {
        return instance.get(`mrm/member/${customerId}/`)
    },
    addContactImage(img,customerId) {
        const formData = new FormData();
        formData.append("img", img);
        return instance.post(`mrm/member/${customerId}/`, formData, fileLoader)
    },
    editPhoneMailContact(contactId,inputData,kindContact,customerId) {
        const formData = new FormData();
        formData.append("phone", inputData);
        formData.append("member", customerId);
        if (kindContact[0]){
            formData.append("kindContact", kindContact[0]);
            if (kindContact[1]){
                formData.append("kindContact", kindContact[1]);
                if (kindContact[2]){
                    formData.append("kindContact", kindContact[2]);
                    if (kindContact[3]){
                        formData.append("kindContact", kindContact[3]);
                        if (kindContact[4]){
                            formData.append("kindContact", kindContact[4]);
                        }
                    }
                }
            }
        }
        return instance.put(`mrm/phone/${contactId}/`, formData)
    }
};


export const DemandsAPI = {
    getDemandsList(customerID,page,pageSize) {
        return instance.get(`mrm/demands/?member=${customerID}&page=${page}&offset=${pageSize}`)
    },
    addNewDemand(demandForm) {
        return instance.post(`mrm/demands/`, demandForm)
    }
}


export const CatalogAPI = {
    getCatalog() {
        return instance.get(`catalog/`)
    },
    getProductsCatalog(filters) {
        const actualFilters = filters.filter( filter => filter.value !== null).map( filter => filter.name+'='+filter.value ).join('&')
        return instance.get(
            `product.json/?${actualFilters}`
        )
    },
    getProduct(productId) {
        return instance.get(`product.json/?id=${productId}&pricemin=1&status=on`)
    },
}

export const PagesAPI = {
    loadOpt() {
        return instance.get(`optom/`)
    },
    catalogOpt(request) {
        return instance.get(`${request}`)
    }
}

export const CartAPI = {
    sendOrder(orderForm) {
        const formData = new FormData();
        formData.append("name", orderForm.name);
        formData.append("phone", orderForm.phone);
        formData.append("email", orderForm.email);
        let i = 0;
        while (i < orderForm.products.length) {
            formData.append("products", orderForm.products[i]);
            i++;
        }
        formData.append("status", 'received');
        return instance.post(`demand/`,formData)
    }
}
