import React, {useEffect} from "react";
import {connect} from "react-redux";
import {loadOpt} from "../../../../../Redux/FrontReducers/optReducer";
import {Link} from "react-router-dom";
import style from './opt.module.css'
import {Helmet} from "react-helmet";

import PhoneIcon from '@material-ui/icons/Phone';


const OptoviePostavki = (props) => {

    useEffect(() => {
        props.loadOpt()
    }, [props.pages.length])

    useEffect(() => {
    }, [])


    return (
        <div className={style.wrapper}>
            <Helmet>
                <title>Магазин "Точка" - Оптовые поставки компютерной техники</title>
            </Helmet>

            <h1>Оптовые поставки компютерной техники</h1>

            <p><b>Магазин "Точка" осущесвляет оптовые поставки компьютерной и цифровой техники по Республике Казахстан.</b></p>
            <p>Мы работаем только с официальным представителями мировых производителей компьютеров и комплектующих.</p>


            <h2>Оптовые поставки</h2>

            <div className={style.categoryList}>
                {
                    props.pages.length &&
                        props.pages.map(page =>
                            <div key={page.id}>
                                <Link to={page.slug} className={style.categoryItem}>
                                    <img className={style.svg}
                                         src={page.image}
                                         width="20px"/>
                                    <span>{page.showTitle}</span>
                                </Link>
                            </div>
                        )
                }
            </div>

                <h2>Оптовые поставки</h2>
                <div className={style.phonesBlock}>
                    <p>Заказать компьютерную технику оптом вы можете по телефонам:</p>
                    <p><a href="tel:+77072174105"><div className={style.button}><PhoneIcon /> +7 (707) 217-41-05</div></a></p>

                    <p><strong>Номер телефона поддержки</strong></p>
                    <p><a href="tel:+77089725848"><div className={style.button}><PhoneIcon /> +7 (708) 972 58 48</div></a></p>
                </div>


        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        pages: state.opt.page,
        isFetching: state.opt.isFetching
    }
}

export default connect(mapStateToProps,{loadOpt})(OptoviePostavki);
