import React, {useState} from "react";
import style from "./topMenu.module.css";
import './mobile.css'
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

import MenuIcon from '@material-ui/icons/Menu';
import PhoneIcon from '@material-ui/icons/Phone';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import GeneralMenuItems from "../../../Objects/GenerlMenuItems";
import { CSSTransition } from 'react-transition-group';
import {connect} from "react-redux";
import Badge from "@material-ui/core/Badge";
import {Link} from "react-router-dom";
import {searchFilter} from "../../../../Redux/FrontReducers/CatalogReducers/filtersReducer";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import IconsButton from "../../../Objects/Icons/InonsButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {addProductToCart, deleteProductFromCart} from "../../../../Redux/FrontReducers/CatalogReducers/cartReducer";


const useStyles = makeStyles({
    list: {
        width: 250,
        background: "rgba(0,0,0,0.8)"
    },
    fullList: {
        width: 'auto',
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: "10%",
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
});

const TopMenu = (props) => {

    const classes = useStyles();

    const [showButton, setShowButton] = useState(true);
    const [showGeneralMenu, setShowGeneralMenu] = useState(true);

    const [showPhoneMenu, setShowPhoneMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showCart, setShowCart] = useState(false);


    const showOnlyPhone = () => {
        setShowSearch(false)
        setShowCart(false)
        setShowGeneralMenu(false)
    }

    const showOnlySearch = () => {
        setShowPhoneMenu(false)
        setShowCart(false)
        setShowGeneralMenu(false)
    }

    const showOnlyCart = () => {
        setShowPhoneMenu(false)
        setShowSearch(false)
        setShowGeneralMenu(false)
    }




    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = anchor => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <div className={style.modalMenu}>
                <GeneralMenuItems />
            </div>
        </div>
    );


    let [searchValue, setSearchValue] = useState('');

    const searchValueChange = (e) => {
        setSearchValue(e.currentTarget.value)
    };

    const changeFilter = () => {
        props.searchFilter('search',searchValue)
    }

    return (
        <>
        <div className={style.wrapper}>
            <div className={style.generalMenu}>
                <div className={style.menuGrid}>
                    <GeneralMenuItems />
                </div>
                <div className={style.burger}>
                    {['top'].map(anchor => (
                        <React.Fragment key={anchor}>
                            <MenuIcon onClick={toggleDrawer(anchor, true)}/>
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </React.Fragment>
                    ))}
                </div>
            </div>


            <div className={showPhoneMenu ? style.phonesMenuActive : style.phonesMenu} onClick={() => setShowPhoneMenu(!showPhoneMenu)}>
                <PhoneIcon />
                <CSSTransition in={showPhoneMenu}
                               timeout={300}
                               classNames="phoneMenu"
                               unmountOnExit
                               onEnter={() => showOnlyPhone()}
                               onExited={() => setShowButton(true)}>
                    <div className="phoneMenu" onClose={() => setShowPhoneMenu(false)}>
                        <span><a href={"tel:+77089725848"}>+7 (708) 972-58-48</a></span>
                        <span><a href={"tel:+77072174105"}>+7 (707) 217-41-05</a></span>
                        <span><a href={"tel:+77279725848"}>+7 (727) 972-58-48</a></span>
                        <span><a href={"tel:+77273756660"}>+7 (727) 375-66-60</a></span>
                    </div>
                </CSSTransition>
            </div>


            <div className={showSearch ? style.searchMenuActive : style.searchMenu} onClick={() => setShowSearch(!showSearch)}>
                <SearchIcon />
            </div>


            <div className={style.cartMenu} onClick={() => setShowCart(!showCart)}>

                    <IconsButton color={showCart ? "#6e0f0f" : "gray"} bgcolor={"white"} aria-label="cart button" component="span">
                        <Badge badgeContent={props.cart.length} color="secondary" max={99}
                               anchorOrigin={{
                                   vertical: 'top',
                                   horizontal: 'right',
                               }}>
                            <ShoppingCartIcon />
                        </Badge>
                    </IconsButton>

                <span className={style.menuItemTitle}>Корзина</span>
                <CSSTransition in={showCart}
                               timeout={300}
                               classNames="cartMenu"
                               unmountOnExit
                               onEnter={() => showOnlyCart()}
                               onExited={() => setShowButton(true)}>
                    <div className="cartMenu" onClose={() => showCart(false)}>
                        {
                            props.cart.length
                                ?
                                <>
                                    <div><b>Товары в корзине</b></div>
                                    <div>
                                        {
                                            props.cart.filter((product,index) => (index === 0 || index === 1)).map((product,index) =>
                                                <div key={index} className={style.productMiniCart}>
                                                    <img src={product.image}/>
                                                    <p>{product.title}</p>
                                                    <div>
                                                        <IconButton aria-label="delete" onClick={() => props.deleteProductFromCart(product.id)}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className={style.otherInCart}>
                                        {
                                            props.cart.length > 2 && <p>...и ещё {props.cart.length-2}</p>
                                        }
                                    </div>
                                    <div>
                                        <Link to={"/cart"}>
                                            перейти в корзину
                                        </Link>
                                    </div>
                                </>
                                : <span><b>Корзина пока пуста</b></span>
                        }
                    </div>
                </CSSTransition>
            </div>
            <div className={style.userMenu}>
                <a href={'https://wa.me/77074075730'} target={'blank'}>
                    <IconsButton color={"white"} bgcolor={"green"} aria-label="whatsapp button" component="span">
                        <WhatsAppIcon />
                    </IconsButton>
                </a>
                <a href={'https://wa.me/77074075730'}>
                    <span className={style.menuItemTitle}>Консультация</span>
                </a>
            </div>
            <CSSTransition in={showSearch}
                           timeout={300}
                           classNames="phoneMenu"
                           unmountOnExit
                           onEnter={() => showOnlySearch()}
                           onExited={() => setShowButton(true)}>
                <div className="phoneMenu" onClose={() => setShowSearch(false)}>
                    <Paper component="form" className={classes.root}>
                        <InputBase
                            className={classes.input}
                            placeholder="Поиск"
                            inputProps={{ 'aria-label': 'search to4ka' }}
                            onChange={(e) => searchValueChange(e)}
                        />
                        <Link to={'/catalog'}>
                            <IconButton type={"submit"}
                                        className={classes.iconButton}
                                        onClick={changeFilter}
                                        aria-label="search">
                                <SearchIcon/>
                            </IconButton>
                        </Link>
                    </Paper>
                </div>
            </CSSTransition>

        </div>

        </>
    )
}

let mapStateToProps = (state) => {
    return {
        cart: state.cart.products
    }
}

export default connect(mapStateToProps,{searchFilter, deleteProductFromCart})(TopMenu)

