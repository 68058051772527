import {CustomerInfoAPI} from "../../API/API";

const SET_CONTACT_INFO = 'SET_CONTACT_INFO';
const SET_PHONE_CONTACT = 'SET_PHONE_CONTACT';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const GET_REDIRECT = 'GET_REDIRECT';

let initialState = {
    result: [],
    contactInfo: {
        member: {
            id: null,
            title: "",
            fullName: "",
            name: "",
            patronymic: ""
        },
        phones: [],
        emails: []
    },
    isFetching: false,
    getRedirect: false,
    phoneKinds: [],
    mailKinds: []
};

const customerContactReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CONTACT_INFO: {
            return {
                ...state,
                contactInfo: action.contactInfo
            };
        }
        case SET_PHONE_CONTACT: {
            return {
                ...state.contactInfo,
                phones: [ ...state.contactInfo.phones, action.phone ]

            };
        }
        case TOGGLE_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.isFetching
            };
        }
        case GET_REDIRECT: {
            return {
                ...state,
                getRedirect: action.getRedirect
            };
        }
        default:
            return state;
    }
};

const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
const getRedirect = (getRedirect) => ({ type: GET_REDIRECT, getRedirect });
const setContactInfo = (contactInfo) => ({ type: SET_CONTACT_INFO, contactInfo });
const setPhoneMailContact = (phone) => ({ type: SET_PHONE_CONTACT, phone });


export const addOrUpdateContact = (inputData,kindContactForm) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.addOrUpdateContact(inputData,kindContactForm);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        dispatch(setContactInfo(response.data));
        if (response.data.member.id) {
            dispatch(getRedirect(true))
        }
    }
};

export const getContactInfo = (contactId) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.getContactInfo(contactId);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        dispatch(getRedirect(false))
        dispatch(setContactInfo(response.data));
    }
};
export const updateContact = (inputData,typeContact,customerId) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.updateContact(inputData,typeContact,customerId);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        dispatch(setContactInfo(response.data));
    }
};
export const updateContact2 = (inputData,typeContact,customerId) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.updateContact2(inputData,typeContact,customerId);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        dispatch(setContactInfo(response.data));
    }
};
export const addContactImage = (customerId,img) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.addContactImage(customerId,img);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        dispatch(setContactInfo(response.data));
    }
};

export const editPhoneMailContact = (contactId,inputData,typeContact,customerId) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.editPhoneMailContact(contactId,inputData,typeContact,customerId);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        dispatch(getContactInfo(customerId));
    }
};


export default customerContactReducer;
