import React from 'react';
import style from './imageBlock.module.css'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Carousel from "react-elastic-carousel";
import Card from "../../ProductsGrid/Card";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: '160px',
        height: '400px'
    },
    miniImage: {
        width: '100px'
    },
    normalImage: {
        maxWidth: '100%'
    }
}));

export default function ImageBlock(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className={style.imageMobileBlock}>
                {
                    props.images.length
                        ?
                        <Carousel itemsToShow={1} transitionMs={100}>

                        {
                            props.images.map((image, index) =>
                                <img key={index} className={style.mobileImage} src={image.image}/>
                            )
                        }

                        </Carousel>
                        :
                        <img src={'http://to4ka.kz'+props.categoryImg} />
                }
            </div>
            <div className={classes.root+' '+style.imageBlock}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    {
                        props.images.length
                            ?
                            props.images.map((image,index) =>
                                <Tab key={index} label={<img className={classes.miniImage} src={image.image}/>} {...a11yProps(index)} />
                            )
                            : <Tab label={<img className={classes.miniImage}  src={'http://to4ka.kz'+props.categoryImg} />} {...a11yProps(1)} />
                    }
                </Tabs>
                {
                    props.images.length
                        ?
                        props.images.map((image,index) =>
                            <TabPanel key={index} value={value} index={index}>
                                <img className={classes.normalImage} src={image.image}/>
                            </TabPanel>
                        )
                        :

                            <img className={classes.normalImage} src={'http://to4ka.kz'+props.categoryImg} />

                }
            </div>
        </>
    );
}
