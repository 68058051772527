import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {getCatalogOpt} from "../../../../../Redux/FrontReducers/CatalogReducers/productsGridReducer";

import style from './opt.module.css'
import CategoryLinks from "../../../../Objects/CategoryLinks";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";


const OptCategory = (props) => {

    useEffect(() => {
        props.getCatalogOpt(props.page.api)
    }, [props.page.api])

    return (
        <div className={style.wrapper}>
            <Helmet>
                <title>{props.page.plural+' | Магазин "Точка"'}</title>
            </Helmet>
            <h1>{props.page.plural} оптом</h1>
            <div className={style.center}>
                <p>Сейчас на складах Казахстана доступны <CategoryLinks categoryId={props.page.id}>{props.page.plural} {props.page.productCount} моделей</CategoryLinks>.</p>
                <p>Наши менеджеры подберут {props.page.title} под ваши требования (техспецификацию).</p>
                <p>Оставьте ваш запрос по телефону:</p>

                <p>
                    <a href="tel:+77072174105">
                        <div className={style.buttonWhatsapp}>
                            <div className={style.button+" "+style.buttonGroupFirst}>
                                <PhoneIcon /> +7 (707) 217-41-05
                            </div>
                            <a href={'https://wa.me/77074075730?text=Интересуют '+props.page.plural+' оптом'} target={'blank'} className={style.buttonGroupSecond}>
                                <WhatsAppIcon />
                            </a>
                        </div>
                    </a>
                </p>

                <p>Или отправьте на электронную почту: <a href={"mail:store@to4ka.kz"}>store@to4ka.kz</a></p>

                <p><i>*Если интересующих Вас товаров нет в Казахстане, мы доставим вам их с завода производителя в течении 6-8 недель.</i></p>


                <h2>Цена на {props.page.plural} в Казахстане</h2><br/>
                <p>Сейчас в Казахстане стоимость за {props.page.title} составляет от <b>{props.rangePrice.minPriceFormat} ₸</b> до <b>{props.rangePrice.maxPriceFormat} ₸</b></p>

            </div>
            <h2>Недорогие {props.page.plural}</h2>
            <p><b><i>*Стоимость снижается в зависимости от количества товара, который вы покупаете.</i></b></p>
            {
                props.products.length
                ?
                    <table width={"100%"}>
                        {props.products.map((product, index) =>
                            <tr key={index}>
                                <td width={"140px"} height={"80px"}>
                                    <img width={"100px"} src={product.images.map((image, index) => {if (index === 0) return image.image}).join('')}/>
                                </td>
                                <td>
                                    <CategoryLinks productId={product.id}>
                                        {product.title}<hr width={"100%"}/>
                                    </CategoryLinks>
                                </td>
                                <td width={"100px"}>
                                    <strong>{product.store.map((store, index) => {if (index === 0) return store.storePrice}).join('')}</strong>
                                </td>
                            </tr>
                        )}
                    </table>
                : <></>
            }

            <CategoryLinks categoryId={props.page.id}>
                Все {props.page.plural}
            </CategoryLinks>

            <div className={style.center}>
                <h2>Брендовые {props.page.plural} оптом</h2><br/>
                <p>{props.page.plural} мировых производителей представлены в Казахстане следуюшими брендами: </p>
                <div>
                    {
                        props.vendors.length
                            ?
                                props.vendors.map((vendor, index) =>
                                    <span key={index}>
                                        <CategoryLinks vendorId={vendor.id} categoryId={props.page.id}>
                                            <img width={"120px"} alt={vendor.title} src={'http://to4ka.kz'+vendor.image}/>
                                        </CategoryLinks>
                                    </span>
                                )
                            : <></>
                    }
                </div>
                <h2> {props.page.plural} - профессиональный подбор</h2>
                <p>Наши специалисты подберут {props.page.title} под ваши задачи для комфортной работы, а также предложат аксессуары для вашего выбора.</p>
                {/*<p>Наши специалисты подберут {props.page.title} под ваши задачи для комфортной работы, а также предложат аксессуары для вашего выборы.</p>*/}

                <h2>Заказать {props.page.plural} оптом</h2>
                <p>Заказать {props.page.plural} оптом вы можете по телефонам:</p>

                <p>
                    <a href="tel:+77072174105">
                        <div className={style.buttonWhatsapp}>
                            <div className={style.button+" "+style.buttonGroupFirst}>
                                <PhoneIcon /> +7 (707) 217-41-05
                            </div>
                            <a href={'https://wa.me/77074075730?text=Интересуют '+props.page.plural+' оптом'} target={'blank'} className={style.buttonGroupSecond}>
                                <WhatsAppIcon />
                            </a>
                        </div>
                    </a>
                </p>

                <p>Звоните в Алматы:</p>

                <p><a href="tel:+77089725848"><div className={style.button}><PhoneIcon /> +7 (708) 972 58 48</div></a></p>

            </div>

        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        products: state.productsGrid.products,
        productsCount: state.productsGrid.productsCount,
        categories: state.productsGrid.categories,
        vendors: state.productsGrid.vendors,
        rangePrice: state.productsGrid.rangePrice,
        isFetching: state.opt.isFetching
    }
}

export default connect(mapStateToProps,{getCatalogOpt})(OptCategory);
