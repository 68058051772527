import React from "react";
import styles from "./FormControls.module.css";
import TextField from "@material-ui/core/TextField";

const FormControl = ({input, meta, child, ...props}) => {

    const hasError = meta.touched && meta.error;
    return (
        <div className={styles.formControl + " " + (hasError ? styles.error : "")}>
            <div>
                {props.children}
            </div>
            { hasError && <span>{meta.error}</span>}
        </div>
    )

};


export const Textarea = (props) => {
    const {input, meta, child, ...restProps} = props;
    return <FormControl {...props}><textarea {...input} {...restProps} /></FormControl>
};

export const Input = (props) => {
    const {input, meta, child, ...restProps} = props;
    return <FormControl {...props}><input {...input} {...restProps} /></FormControl>
};

export const renderTextField = ({ label,input,meta: { touched, invalid, error },...custom}) => (
    <TextField label={label}
               placeholder={label}
               error={touched && invalid}
               helperText={touched && error}
               {...input}
               {...custom}
    />
);
