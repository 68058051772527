import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {addFilter} from "../../../Redux/FrontReducers/CatalogReducers/filtersReducer";


const CategoryLinks = (props) => {
    return (
        <Link to={props.productId ? `/catalog/product/${props.productId}` : '/catalog/'}
              onClick={() => {
                  props.addFilter('status',"on")
                  props.categoryId ? props.addFilter('category', props.categoryId) : props.addFilter('category', null)
                  props.vendorId ? props.addFilter('vendor', props.vendorId) : props.addFilter('vendor', null)
                  props.productId ? props.addFilter('id', props.productId) : props.addFilter('id', null)
                  props.addFilter('search', null)
                  props.addFilter('page', 1)
              }}
        >
            {props.children}
        </Link>
    )
}


export default connect( null, { addFilter } )(CategoryLinks)
