import React from "react"
import style from "./productGrid.module.css"
import {connect} from "react-redux"

import Card from "./Card"
import {getProductsCatalog} from "../../../../Redux/FrontReducers/CatalogReducers/productsGridReducer"
import Pagination from "@material-ui/lab/Pagination";
import Carousel from 'react-elastic-carousel'
import LinearProgress from "@material-ui/core/LinearProgress";
import FiltersBlock from "../Catalog/FiltersBlock";
import {addFilter} from "../../../../Redux/FrontReducers/CatalogReducers/filtersReducer";
import {addProductToCart, deleteProductFromCart} from "../../../../Redux/FrontReducers/CatalogReducers/cartReducer";



class ProductsGrid extends React.PureComponent {

    componentDidMount() {
        this.props.getProductsCatalog(this.props.filters)

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filters !== this.props.filters) {
            this.props.getProductsCatalog(this.props.filters)
        }
    }

    onPageChanged = (event, pageNumber) => {
        this.props.addFilter('page', pageNumber)
    };

    render() {

        let currentPage = this.props.filters.filter( filter => filter.name === 'page').map( filter => filter.value )[0]

        let pagesCount = Math.ceil(this.props.productsCount / this.props.pageSize);

        return (
            <>
            {
                this.props.isFetchingProduct
                    ? <LinearProgress color="secondary" className={style.wrapper} />
                    :
                    <>
                        <FiltersBlock filters={this.props.filters}
                                      addFilter={this.props.addFilter}
                                      categories={this.props.categories}
                                      vendors={this.props.vendors}
                                      productsCount={this.props.productsCount}
                                      rangePrice={this.props.rangePrice}
                        />
                        <div className={style.wrapper}>
                            <h1 className={style.title}>
                            </h1>

                            {
                                /*
                                <Breadcrumbs className={style.breadcrumbs} aria-label="breadcrumb">
                                <Link to="/">
                                    <HomeIcon />
                                </Link>
                                <Link to="/catalog">
                                    Каталог
                                </Link>
                                <Typography color="textPrimary">Товары по запросу</Typography>
                            </Breadcrumbs>
                                * */
                            }


                            <div className={style.gridMobileCards}>
                                <Carousel itemsToShow={1} transitionMs={100}>
                                    {
                                        this.props.productsCount
                                            ?
                                            this.props.products.map((product, index) =>
                                                <Card key={index}
                                                      product={product}
                                                      cart={this.props.cart}
                                                      addProductToCart={this.props.addProductToCart}
                                                      deleteProductFromCart={this.props.deleteProductFromCart}
                                                      categoryImg={this.props.categories[0].image/*.filter( category => category.id === product.category).map( category => category.image )[0]*/}
                                                />
                                            )
                                            :
                                            <div className={style.notFound}>Товары по запросу не найдены</div>
                                    }

                                </Carousel>
                            </div>

                            <div className={style.gridProductCards}>
                                {
                                    this.props.productsCount
                                        ?
                                        this.props.products.map((product, index) =>
                                            <Card key={index}
                                                  product={product}
                                                  cart={this.props.cart}
                                                  addProductToCart={this.props.addProductToCart}
                                                  deleteProductFromCart={this.props.deleteProductFromCart}
                                                  categoryImg={this.props.categories[0].image}
                                            />
                                        )
                                        :
                                        <div className={style.notFound}>Товары по запросу не найдены</div>
                                }
                            </div>

                            <div className={style.paginator}>
                                {
                                    this.props.productsCount
                                        ? <Pagination count={pagesCount} page={currentPage} onChange={this.onPageChanged}
                                                      siblingCount={1}/>
                                        : <></>
                                }
                            </div>
                        </div>
                    </>
            }
            </>
        );
    }
}

let mapStateToProps = (state) => {
    return {
        products: state.productsGrid.products,
        productsCount: state.productsGrid.productsCount,
        categories: state.productsGrid.categories,
        vendors: state.productsGrid.vendors,
        rangePrice: state.productsGrid.rangePrice,
        filters: state.filters.filters,
        currentPage: state.productsGrid.currentPage,
        pageSize: state.productsGrid.pageSize,
        isFetchingProduct: state.productsGrid.isFetchingProduct,
        cart: state.cart.products
    }
}

export default connect( mapStateToProps, { getProductsCatalog, addFilter, addProductToCart, deleteProductFromCart } )(ProductsGrid)
