import Cookies from 'js-cookie'
import {CartAPI} from "../../../API/API";

const TOGGLE_IS_FETCHING_CATALOG = 'TOGGLE_IS_FETCHING_CATALOG'
const ADD_TO_CART = 'ADD_TO_CART'
const DELETE_FROM_CART = 'DELETE_FROM_CART'
const SET_CART = 'SET_CART'
const ERASE_CART = 'ERASE_CART'

let initialState = {
    products: [],
    isFetchingCatalog: false
}

const cartReducer = (state = initialState, action) => {
    switch(action.type) {
        case ADD_TO_CART: {
            //Cookies.set('cart', state.products);
            return {
                ...state,
                products: [...state.products, { id: action.productId, title: action.productTitle, image: action.productImage}]
            };
        }
        case DELETE_FROM_CART: {
            //Cookies.set('cart', state.products);
            return {
                ...state,
                products: state.products.filter(product => product.id !== action.productId)
            };
        }
        case SET_CART: {
            return {
                ...state,
                products: action.products
            };
        }
        case ERASE_CART: {
            return {
                ...state,
                products: []
            };
        }
        case TOGGLE_IS_FETCHING_CATALOG: {
            return {
                ...state,
                isFetchingCatalog: action.isFetchingCatalog
            };
        }
        default:
            return state
    }
}

const toggleIsFetchingCatalog = (isFetchingCatalog) => ({ type: TOGGLE_IS_FETCHING_CATALOG, isFetchingCatalog })
const addToCart = (productId,productTitle,productImage) => ({ type: ADD_TO_CART, productId,productTitle,productImage })
const deleteFromCart = (productId) => ({ type: DELETE_FROM_CART, productId })
const setCart = (products) => ({ type: SET_CART, products })
const eraseCart = () => ({ type: ERASE_CART })



export const getCart = () => async (dispatch) => {
    dispatch(toggleIsFetchingCatalog(true))
    /*console.log(Cookies.get('cart'))
    if (Cookies.get('cart')) {
        const cart = Cookies.get('cart');
        dispatch(setCart(cart))
    }*/
    dispatch(toggleIsFetchingCatalog(false))
}

export const addProductToCart = (productId,productTitle,productImage) => async (dispatch) => {
    dispatch(toggleIsFetchingCatalog(true))
    dispatch(addToCart(productId,productTitle,productImage))
    dispatch(toggleIsFetchingCatalog(false))
}

export const deleteProductFromCart = (productId) => async (dispatch) => {
    dispatch(toggleIsFetchingCatalog(true))
    dispatch(deleteFromCart(productId))
    dispatch(toggleIsFetchingCatalog(false))
}

export const sendOrder = (form) => async (dispatch) => {
    dispatch(toggleIsFetchingCatalog(true))
    let response = await CartAPI.sendOrder(form)
    dispatch(eraseCart())
    dispatch(toggleIsFetchingCatalog(false))
}

export default cartReducer
