import {DemandsAPI} from "../../API/API";

const SET_DEMANDS_LIST = 'SET_DEMANDS_LIST';
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const SWITCH_MOD_DEMAND = 'SWITCH_MOD_DEMAND';
const SWITCH_MOD_DEMAND_TF = 'SWITCH_MOD_DEMAND_TF';


let initialState = {
    demandsList: {
        resultCount: 0,
        demands: [],
    },
    currentPage: 1,
    pageSize: 5,
    editMode: false,
    isFetching: false
};

const customerDemandsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_DEMANDS_LIST: {
            return {
                ...state,
                demandsList: action.demandsList
            };
        }
        case SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.currentPage
            };
        }
        case TOGGLE_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.isFetching
            };
        }
        case SWITCH_MOD_DEMAND : {
            return {
                ...state,
                editMode: !state.editMode
            };
        }
        case SWITCH_MOD_DEMAND_TF : {
            return {
                ...state,
                editMode: action.tf
            };
        }
        default:
            return state;
    }
};

const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
const setDemandsList = (demandsList) => ({ type: SET_DEMANDS_LIST, demandsList });
const setCurrentPage = (currentPage) => ({ type: SET_CURRENT_PAGE, currentPage });
export const switchModDemand = () => ({ type: SWITCH_MOD_DEMAND })
export const switchModDemandTF = (tf) => ({ type: SWITCH_MOD_DEMAND_TF, tf })

export const getDemandsList = (customerID,page,pageSize) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    dispatch(setCurrentPage(page));
    let response = await DemandsAPI.getDemandsList(customerID,page,pageSize);
    if (response.data.resultCode === 0) {
        dispatch(setDemandsList(response.data));
        if (response.data.resultCount) {
            dispatch(switchModDemandTF(false));
        } else {
            dispatch(switchModDemandTF(true))
        }
    }
    dispatch(toggleIsFetching(false));
};

export const addNewDemand = (demandForm,customerID,page,pageSize) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await DemandsAPI.addNewDemand(demandForm);
    if (response.data.resultCode === 0) {
        dispatch(getDemandsList(customerID,page,pageSize));
    }
    dispatch(toggleIsFetching(false));
};


export default customerDemandsReducer;
