import {CatalogAPI} from "../../../API/API"

const TOGGLE_IS_FETCHING_CATALOG = 'TOGGLE_IS_FETCHING_CATALOG'
const SET_CATALOG = 'SET_CATALOG'

let initialState = {
    catalog: [
        {
            elements: []
        }
    ],
    isFetchingCatalog: false
}

const catalogPageReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CATALOG: {
            return {
                ...state,
                catalog: action.catalog
            };
        }
        case TOGGLE_IS_FETCHING_CATALOG: {
            return {
                ...state,
                isFetchingCatalog: action.isFetchingCatalog
            };
        }
        default:
            return state
    }
}

const toggleIsFetchingCatalog = (isFetchingCatalog) => ({ type: TOGGLE_IS_FETCHING_CATALOG, isFetchingCatalog })
const setCatalog = (catalog) => ({ type: SET_CATALOG, catalog })


export const getCatalog = () => async (dispatch) => {
    dispatch(toggleIsFetchingCatalog(true))
    let response = await CatalogAPI.getCatalog()
    if (response.data.resultCode === 0) {
        dispatch(setCatalog(response.data.catalog))
    }
    dispatch(toggleIsFetchingCatalog(false))
}

export default catalogPageReducer
