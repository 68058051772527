import React, {useEffect, useState} from "react";
import style from "./FiltersBlock.module.css";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import PriceRange from "../../../../Objects/Filters/PriceRange";
import PriceSort from "../../../../Objects/Filters/PriceSort";
import DialogModal from "../../../../Objects/Filters/DialogModal";
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/LibraryAdd';
import makeStyles from "@material-ui/core/styles/makeStyles";
import BackspaceIcon from '@material-ui/icons/Backspace';

const StyledBadge = withStyles((theme) => ({
    badge: {
        left: 13,
        top: -3,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: '#D90015'
    },
}))(Badge);
const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText('#9D0917'),
        backgroundColor: '#9D0917',
        '&:hover': {
            backgroundColor: '#6e0f0f',
        },
        fontSize: '12px!important',
        height: '20px!important',
        alignItems: 'center',
        alignContent: 'center'
    },
}))(Fab);
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const FiltersBlock = (props) => {

    const classes = useStyles();

    let [filtersBlock, setFiltersBlock] = useState({show: true});

    const [openModal, setOpenModal] = React.useState(false);
    const handleClickModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };


    const [openModal1, setOpenModal1] = React.useState(false);
    const handleClickModal1 = () => {
        setOpenModal1(true);
    };
    const handleCloseModal1 = () => {
        setOpenModal1(false);
    };

    /*const [categories, setCategories] = React.useState([])
    const [vendors, setVendors] = React.useState([])
    const [exCategories, setExCategories] = React.useState([])
    const [exVendors, setExVendors] = React.useState([])

    console.log(categories)
    console.log(vendors)
    console.log(exCategories)
    console.log(exVendors)

    const addToFilterCategories = (id) => {
        setCategories(categories.push(id))
    }*/

    const deleteSearch = () => {
        props.addFilter('search',null)
        setFiltersBlock({show: false})
    }
    const reloadCategories = () => {
        props.addFilter('category',null)
        props.addFilter('excategory',null)
    }
    const reloadVendors = () => {
        props.addFilter('vendor',null)
        props.addFilter('exvendor',null)
    }

    const deleteCategory = (categoryId) => {
        let categories = props.categories.filter(category => category.id !== categoryId).map(category => category.id)
        if (!categories.length) categories = null
        props.addFilter('category',categories)
    }
    const deleteVendor = (vendorId) => {
        let vendors = props.vendors.filter(vendor => vendor.id !== vendorId).map(vendor => vendor.id)
        if (!vendors.length) vendors = null
        props.addFilter('vendor',vendors)
    }

    const addPriceRange = (priceMin,priceMax) => {
        props.addFilter('pricemin',priceMin)
        props.addFilter('pricemax',priceMax)
    }

    const choseCategory = (categoryId) => {
        props.addFilter('category',categoryId)
        let vendors = props.vendors.map(vendor => vendor.id)
        if (!vendors.length) vendors = null
        props.addFilter('vendor',vendors)
        handleCloseModal()
    }
    const choseVendor = (vendorId) => {
        props.addFilter('vendor',vendorId)
        let categories = props.categories.map(category => category.id)
        if (!categories.length) categories = null
        props.addFilter('category',categories)
        handleCloseModal1()
    }

    const excludeCategory = (categoryId) => {
        props.addFilter('excategory',categoryId)
        handleCloseModal()
    }
    const excludeVendor = (vendorId) => {
        props.addFilter('exvendor',vendorId)
        handleCloseModal1()
    }


    let chipCount = 5
    let windowWidth = document.body.clientWidth
    if (windowWidth > 1900) chipCount = 8
    else if (windowWidth > 1400) chipCount = 6
    else if (windowWidth > 900) chipCount = 5


    useEffect(() => {
        if (windowWidth < 200) setFiltersBlock({show:false})
    }, [windowWidth])

    return (
        <div className={style.wrapper}>
            <div className={filtersBlock.show ? style.filtersBlock : style.filtersBlockHide}>
                <div className={style.filtersLabel}>
                    <span>Найдено: <b>{props.productsCount}</b></span>
                    {/*<ColorButton size="small"
                                 variant="extended"
                                 color="primary"
                                 onClick={handleClickModal}
                                 className={classes.margin+" "+style.filtersButton}>
                        <AddIcon className={classes.extendedIcon}/> фильтры
                    </ColorButton>*/}
                </div>
                <div className={style.activeFilters}>

                        {
                            props.filters.map(filter => {
                                if (filter.name === 'search' && filter.value !== '' && filter.value !== null) return filter.value}).join('')
                                &&
                                <span className={style.vendorsBlock}>
                                    <b className={style.vendorsTitle}>Ключевое слово:</b>
                                    <span>
                                        <Chip label={props.filters.map(filter => {if (filter.name === 'search' && filter.value !== '' && filter.value !== null) return filter.value}).join('')}
                                              onDelete={deleteSearch}
                                              variant="outlined" />
                                    </span>
                                </span>
                        }

                        {
                            props.categories.length ?
                            <span className={style.vendorsBlock}>
                            <b className={style.vendorsTitle}>Категории ({props.categories.length})</b>
                            <i className={style.filterReload} onClick={reloadCategories}><BackspaceIcon /></i>
                                {props.categories.map((category, index) => {
                                        if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4) {
                                            return (
                                                <span key={index}>
                                                    <StyledBadge badgeContent={category.count} color="secondary" max={9999}
                                                                 anchorOrigin={{
                                                                     vertical: 'top',
                                                                     horizontal: 'left',
                                                                 }}>
                                                    <Chip label={category.title}
                                                          size="small"
                                                          avatar={category.image ? <Avatar alt={category.title}
                                                                                           src={category.image && "http://to4ka.kz" + category.image}/> :
                                                              <Avatar>К</Avatar>}
                                                          onDelete={() => deleteCategory(category.id)}
                                                          onClick={() => choseCategory(category.id)}
                                                          variant="outlined"/>
                                                    </StyledBadge>
                                                </span>
                                            )
                                        }
                                    }
                                )}
                                {(props.categories.length > 5) ? <Chip label={"Отобразить ещё "+(props.categories.length-5)+"..."}
                                                                    size="small"
                                                                    onClick={handleClickModal1}/>
                                    : <></>}
                            </span> : <></>
                        }



                        {
                            props.vendors.length ?
                            <span className={style.vendorsBlock}>
                            <b className={style.vendorsTitle}>Производители ({props.vendors.length})</b>
                            <i className={style.filterReload} onClick={reloadVendors}><BackspaceIcon /></i>
                                {props.vendors.map((vendor, index) => {
                                        if (index === 0 || index === 1 || index === 2 || index === 3 || index === 4) {
                                            return (
                                                <span key={index}>
                                                    <StyledBadge badgeContent={vendor.count} color="secondary"
                                                                 max={9999}
                                                                 anchorOrigin={{
                                                                     vertical: 'top',
                                                                     horizontal: 'left',
                                                                 }}>
                                                    <Chip label={vendor.title}
                                                          size="small"
                                                          avatar={vendor.image ? <Avatar alt={vendor.title}
                                                                                         src={vendor.image && "http://to4ka.kz" + vendor.image}/> :
                                                              <Avatar>{vendor.title}</Avatar>}
                                                          onDelete={() => deleteVendor(vendor.id)}
                                                          onClick={() => choseVendor(vendor.id)}
                                                          variant="outlined"/>
                                                    </StyledBadge>
                                                </span>
                                            )
                                        }
                                    }
                                )}
                                {(props.vendors.length > 5) ? <Chip label={"ещё "+(props.vendors.length-5)+"..."}
                                                                    size="small"
                                                                    onClick={handleClickModal}/>
                                    : <></>}
                            </span> : <></>
                        }

                </div>
                <div className={style.sort}>

                    <PriceRange rangePrice={props.rangePrice} addPriceRange={addPriceRange} />

                    <PriceSort addFilter={props.addFilter} filters={props.filters} />


                </div>
            </div>
            <DialogModal open={openModal1}
                         handleClose={handleCloseModal1}
                         chose={choseCategory}
                         exclude={excludeCategory}
                         filter={props.categories}
                         filterTitle={"Категории"} />
            <DialogModal open={openModal}
                         handleClose={handleCloseModal}
                         chose={choseVendor}
                         exclude={excludeVendor}
                         filter={props.vendors}
                         filterTitle={"Производители"}/>
                {/*<div className={style.activeFilters}>


                        props.categories.length ?
                            <span className={style.vendorsBlock}>
                            <b className={style.vendorsTitle}>Категории ({props.categories.length})</b>
                                {props.categories.map((category, index) =>
                                    <span key={index}>
                                        <StyledBadge badgeContent={category.count} color="secondary" max={9999}
                                                     anchorOrigin={{
                                                         vertical: 'top',
                                                         horizontal: 'left',
                                                     }}>
                                            <Chip label={category.title}
                                                  size="small"
                                                  avatar={category.image ? <Avatar alt={category.title}
                                                                                   src={category.image && "http://to4ka.kz" + category.image}/> :
                                                      <Avatar>К</Avatar>}
                                                  onDelete={() => deleteCategory(category.id)}
                                                  onClick={() => addToFilterCategories(category.id)}
                                                  color={"secondary"}
                                                  variant="outlined"/>
                                        </StyledBadge>
                                    </span>
                                )}
                            </span> : <></>

                </div>
            </DialogModal>*/}
            <span className={style.expand} onClick={() => setFiltersBlock({show: !filtersBlock.show})}>{filtersBlock.show ? "cвернуть" : "развернуть"} фильтры</span>
            <div className={style.srez}>
            </div>
        </div>
    );
}

export default FiltersBlock;
