import React, {useState} from "react";
import style from "./topBar.module.css";
import logo from "./../../../../Assets/Images/storelogo-150x70.png";
import {Link} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from '@material-ui/icons/Search';
import {connect} from "react-redux";
import {searchFilter} from "../../../../Redux/FrontReducers/CatalogReducers/filtersReducer";
import CategoryLinks from "../../../Objects/CategoryLinks";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const TopBar = (props) => {

    const classes = useStyles();

    let [searchValue, setSearchValue] = useState('');

    const searchValueChange = (e) => {
        setSearchValue(e.currentTarget.value)
    };

    const changeFilter = () => {
        props.searchFilter('search',searchValue)
    }

    return (
        <div className={style.wrapper}>
            <div className={style.logo}>
                <CategoryLinks>
                    <img src={logo} />
                </CategoryLinks>
            </div>
            <div className={style.search}>
                <Paper component="form" className={classes.root}>
                    <InputBase
                        className={classes.input}
                        placeholder="Поиск"
                        inputProps={{ 'aria-label': 'search to4ka' }}
                        onChange={(e) => searchValueChange(e)}
                    />
                    <Link to={'/catalog'}>
                        <IconButton type={"submit"}
                                    className={classes.iconButton}
                                    onClick={changeFilter}
                                    aria-label="search">
                            <SearchIcon/>
                        </IconButton>
                    </Link>
                </Paper>
            </div>
            <div className={style.phones}>
                <span><a href={"tel:+77089725848"}>+7 (708) 972-58-48</a></span>
                <span><a href={"tel:+77072174105"}>+7 (707) 217-41-05</a></span>
                <span><a href={"tel:+77279725848"}>+7 (727) 972-58-48</a></span>
                <span><a href={"tel:+77273756660"}>+7 (727) 375-66-60</a></span>
            </div>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        filters: state.filters.filters
    }
}

export default connect( mapStateToProps, { searchFilter } )(TopBar);

