import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import sortToMaxIcon from "./../../../../Assets/Images/icons/sortToMax.svg"
import sortToMinIcon from "./../../../../Assets/Images/icons/sortToMin.svg"

const useStyles = makeStyles((theme) => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    item: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gridGap: "10px",
        alignItems: "center"
    }
}));

const PriceSort = (props) => {

    const classes = useStyles();

    const [sortPrice, setSortPrice] = React.useState(props.filters.filter(filter => filter.name === 'o').map(filter => filter.value)[0]);
    const [open, setOpen] = React.useState(false);

    const handleChange = (event) => {
        props.addFilter('o',event.target.value)
        setSortPrice(event.target.value)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="selectSortLabel">Сортировать</InputLabel>
            <Select
                labelId="selectSortLabel"
                id="selectSort"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={sortPrice}
                onChange={handleChange}
            >
                <MenuItem value={"min"}>
                    <em className={classes.item}><img src={sortToMaxIcon}/> Цена по возрастанию</em>
                </MenuItem>
                <MenuItem value={"max"}>
                    <em className={classes.item}><img src={sortToMinIcon}/> Цена по убыванию</em>
                </MenuItem>
            </Select>
        </FormControl>
    )
}

export default PriceSort;
