import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import withStyles from "@material-ui/core/styles/withStyles";

const InfoTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#333333',
    },
})(Tabs);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    panel: {
        background: '#fff',
        border: 0,
        borderBottom: 0,
        boxShadow: 'none'
    },
    tab: {
        marginLeft: '50px',
        textTransform: 'none',
        color: '#1C1C1C',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        '&:hover': {
            color: '#1C1C1C',
            opacity: 1,
        },
        '&$selected': {
            color: '#1C1C1C',
        },
        '&:focus': {
            color: '#1C1C1C',
        },
    }
}));

export default function InfoBlock(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.panel}>
                <InfoTabs value={value}
                      onChange={handleChange}
                      aria-label="info product tabs">
                    <Tab className={classes.tab} label="Характеристики" {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Описание" {...a11yProps(1)} />
                </InfoTabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {
                    props.harak
                    ? <div>{props.harak}</div>
                    : <div>Характеристики не указаны</div>
                }

            </TabPanel>
            <TabPanel value={value} index={1}>
                Описание отсутсвует
            </TabPanel>
        </div>
    );
}
