import React from "react";
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import Telegram from '@material-ui/icons/Telegram';
import WhatsApp from '@material-ui/icons/WhatsApp';
import FaceIcon from '@material-ui/icons/Face';
import ErrorIcon from '@material-ui/icons/Error';

export const MobileIcon = () => {
    return <PhoneAndroidIcon />
}

export const WhatsAppIcon = () => {
    return <WhatsApp />
}

export const TelegramIcon = () => {
    return <Telegram />
}

export const LandlinePhoneIcon = () => {
    return <PhoneIcon />
}

export const ViberIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21.042"
        height="22.19"
        viewBox="0 0 21.042 22.19">
            <g transform="translate(0 0)">
                <path
                    d="M56.57,28.161c-.55-.507-2.778-2.123-7.744-2.145,0,0-5.855-.351-8.706,2.266-1.586,1.586-2.145,3.913-2.206,6.795s-.134,8.281,5.07,9.746h0l0,2.236s-.035.906.563,1.088c.719.225,1.144-.464,1.833-1.2.377-.407.9-1.005,1.291-1.46A20.138,20.138,0,0,0,53.281,45c.719-.234,4.789-.754,5.447-6.154C59.413,33.271,58.4,29.752,56.57,28.161Zm.6,10.275c-.559,4.507-3.857,4.793-4.464,4.988a18.632,18.632,0,0,1-5.686.485s-2.253,2.717-2.955,3.423c-.23.23-.481.208-.477-.247,0-.3.017-3.714.017-3.714h0c-4.412-1.222-4.152-5.82-4.1-8.225s.5-4.377,1.846-5.7c2.414-2.188,7.384-1.863,7.384-1.863,4.2.017,6.21,1.283,6.678,1.707,1.547,1.326,2.336,4.5,1.759,9.148Zm-6.024-3.5a.28.28,0,1,1-.559.026,1.34,1.34,0,0,0-1.413-1.469.28.28,0,0,1,.03-.559A1.884,1.884,0,0,1,51.149,34.935Zm.88.49a3.237,3.237,0,0,0-3.285-3.436.28.28,0,0,1,.039-.559,3.8,3.8,0,0,1,3.8,4.008A.28.28,0,0,1,52.029,35.424ZM54.065,36a.28.28,0,0,1-.559,0,5.109,5.109,0,0,0-5.235-5.478.28.28,0,0,1,0-.559A5.669,5.669,0,0,1,54.065,36Zm-.49,4.251v.009c-.468.823-1.343,1.733-2.245,1.443l-.009-.013a16.655,16.655,0,0,1-4.429-2.448,11.329,11.329,0,0,1-1.837-1.837,14.069,14.069,0,0,1-1.335-2.019,12.95,12.95,0,0,1-1.127-2.414c-.29-.9.615-1.777,1.443-2.245h.009a.789.789,0,0,1,1.036.169s.537.641.767.958c.217.295.507.767.659,1.031a.9.9,0,0,1-.16,1.153l-.52.416a.763.763,0,0,0-.23.607,5.426,5.426,0,0,0,3.653,3.653.762.762,0,0,0,.607-.23l.416-.52a.9.9,0,0,1,1.153-.16,11.225,11.225,0,0,1,1.985,1.426.774.774,0,0,1,.165,1.023Z"
                    transform="translate(-37.901 -26.001)"
                />
            </g>
    </svg>
}

export const EmailIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 21">
        <path
            d="M28,5H4A4,4,0,0,0,0,9V22a4,4,0,0,0,4,4H28a4,4,0,0,0,4-4V9A4,4,0,0,0,28,5ZM2,10.25,9,15.5,2,20.75ZM30,22a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2l7.832-5.875L14.2,19.4a3,3,0,0,0,3.6,0l4.369-3.277L30,22Zm0-1.25L23,15.5l7-5.25ZM17.2,18.6a2,2,0,0,1-2.4,0l-4.135-3.1-.833-.625L2,9H2A2,2,0,0,1,4,7H28a2,2,0,0,1,2,2Z"
            transform="translate(0 -5)"
        />
    </svg>
}

export const EmailWorkIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="21"
        viewBox="0 0 32 21">
            <g transform="translate(-1225 -931)">
                <path d="M28,5H4A4,4,0,0,0,0,9V22a4,4,0,0,0,4,4H28a4,4,0,0,0,4-4V9A4,4,0,0,0,28,5ZM2,10.25,9,15.5,2,20.75ZM30,22a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2l7.832-5.875L14.2,19.4a3,3,0,0,0,3.6,0l4.369-3.277L30,22Zm0-1.25L23,15.5l7-5.25ZM17.2,18.6a2,2,0,0,1-2.4,0l-4.135-3.1-.833-.625L2,9H2A2,2,0,0,1,4,7H28a2,2,0,0,1,2,2Z"
                      transform="translate(1225 926)"
                />
                <g transform="translate(1231 931.5)" fill="#282d3f">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9" fill="none"/>
                </g>
                <g transform="translate(1238 935.769)">
                    <g transform="translate(0 0)">
                        <path d="M11.9.042h0L8.646,1.327V2.385l.008.02L11.9,1.123l3.154,1.248h0l.085.033v0l.009,0V1.828h0v-.5Z"
                              transform="translate(-8.646 -0.041)"
                        />
                        <path d="M14.6,8.491,11.895,7.443,9.187,8.491V8.5l-.542.227v7.3h6.5v-7.3L14.6,8.5Zm-2.708,6.986H9.728V12.269h2.166Zm0-4.665H9.728V9.184h2.166Zm2.166,3.041H12.436V12.269h1.625Zm0-3.044H12.436V9.186h1.625Z"
                              transform="translate(-8.645 -5.876)"
                        />
                    </g>
                </g>
            </g>
    </svg>
}

export const PayPalIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 32 21">
            <g transform="translate(-1225 -992)">
                <path
                    d="M28,5H4A4,4,0,0,0,0,9V22a4,4,0,0,0,4,4H28a4,4,0,0,0,4-4V9A4,4,0,0,0,28,5ZM2,10.25,9,15.5,2,20.75ZM30,22a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2l7.832-5.875L14.2,19.4a3,3,0,0,0,3.6,0l4.369-3.277L30,22Zm0-1.25L23,15.5l7-5.25ZM17.2,18.6a2,2,0,0,1-2.4,0l-4.135-3.1-.833-.625L2,9H2A2,2,0,0,1,4,7H28a2,2,0,0,1,2,2Z"
                    transform="translate(1225 987)"
                />
                <g transform="translate(1231 993)" fill="#282d3f">
                    <circle cx="10" cy="10" r="10" stroke="none"/>
                    <circle cx="10" cy="10" r="9" fill="none"/>
                </g>
                <path
                    d="M10.221,2.272a2.668,2.668,0,0,1,.27,1.264A3.272,3.272,0,0,1,9.043,6.308,6.771,6.771,0,0,1,5,7.361H4.591a.73.73,0,0,0-.678.532L3.426,9.969a.728.728,0,0,1-.678.533H1.861l-.079.339a.418.418,0,0,0,.429.534H3.632a.727.727,0,0,0,.678-.533L4.8,8.766a.73.73,0,0,1,.678-.532h.406A6.769,6.769,0,0,0,9.927,7.181a3.272,3.272,0,0,0,1.448-2.772,2.672,2.672,0,0,0-.27-1.264,2.14,2.14,0,0,0-.781-.822C10.288,2.3,10.258,2.292,10.221,2.272ZM2.541,9.1,3.028,7.02a.729.729,0,0,1,.678-.532h.406A6.769,6.769,0,0,0,8.158,5.435,3.271,3.271,0,0,0,9.606,2.663,2.669,2.669,0,0,0,9.336,1.4a2.137,2.137,0,0,0-.78-.822A3.565,3.565,0,0,0,7.324.129,9.492,9.492,0,0,0,5.653,0H2.693a.729.729,0,0,0-.679.532L.013,9.1a.417.417,0,0,0,.429.533H1.864A.728.728,0,0,0,2.541,9.1ZM3.7,4.127l.427-1.818a.731.731,0,0,1,.678-.532h.465A2.253,2.253,0,0,1,6.5,2.05a.925.925,0,0,1,.414.833A1.527,1.527,0,0,1,6.273,4.2a3.013,3.013,0,0,1-1.788.463H4.133A.42.42,0,0,1,3.7,4.127Z"
                    transform="translate(1235.625 997.625)"
                />
            </g>
    </svg>
}

export const CallNameIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 32.242 21.473">
        <path
            d="M34.9,89.5H30.065v1.432h.7a.838.838,0,0,1,0,1.677H27.7a.838.838,0,0,1,0-1.677h.7V89.5H15.853v1.432h.7a.838.838,0,0,1,0,1.677H13.487a.838.838,0,0,1,0-1.677h.7V89.5H9.347A3.344,3.344,0,0,0,6,92.847v14.786a3.341,3.341,0,0,0,3.34,3.34H34.9a3.341,3.341,0,0,0,3.34-3.34V92.847A3.342,3.342,0,0,0,34.9,89.5ZM15.2,95.749a2.85,2.85,0,1,1-2.85,2.85A2.851,2.851,0,0,1,15.2,95.749ZM8.973,107.182a5.3,5.3,0,0,1,5.3-5.3h1.851a5.3,5.3,0,0,1,5.3,5.3Zm25.587-1.025h-10.3a.7.7,0,0,1-.7-.7h0a.7.7,0,0,1,.7-.7H34.566a.7.7,0,0,1,.7.7h0A.706.706,0,0,1,34.56,106.156Zm0-3.985h-10.3a.7.7,0,0,1-.7-.7h0a.7.7,0,0,1,.7-.7H34.566a.7.7,0,0,1,.7.7h0A.706.706,0,0,1,34.56,102.171Zm0-3.985h-10.3a.7.7,0,0,1-.7-.7h0a.7.7,0,0,1,.7-.7H34.566a.7.7,0,0,1,.7.7h0A.706.706,0,0,1,34.56,98.186Z"
            transform="translate(-6 -89.5)"
        />
    </svg>
}

export const NameIcon = () => {
    return <FaceIcon />
}

export const SurnameIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 33.762 31.425">
            <ellipse
                cx="2.75"
                cy="2.75"
                rx="2.75"
                ry="2.75"
                transform="translate(4.302 0)"
            />
            <ellipse
                cx="2.75"
                cy="2.75"
                rx="2.75"
                ry="2.75"
                transform="translate(16.087 0)"
            />
            <ellipse
                cx="1.964"
                cy="1.964"
                rx="1.964"
                ry="1.964"
                transform="translate(27.479 16.891)"
            />
            <path
                d="M38.732,43.719l-1.375-3.3a3.8,3.8,0,0,0-2.043-1.414,5.346,5.346,0,0,0-.825-.079H32.526l-1.65-2.789a1.5,1.5,0,0,0,.039-.589l-1.846-9.271a6.817,6.817,0,0,0-2.789-1.846A7.052,7.052,0,0,0,23.844,24a7.222,7.222,0,0,0-2.435.432,7.378,7.378,0,0,0-2.789,1.846l-.629,3.5-.707-3.5A6.817,6.817,0,0,0,14.5,24.432,7.052,7.052,0,0,0,12.06,24a7.222,7.222,0,0,0-2.435.432,7.378,7.378,0,0,0-2.789,1.846L5.068,35.549a1.164,1.164,0,0,0,.943,1.375.294.294,0,0,0,.2.039,1.142,1.142,0,0,0,1.139-.943l1.611-7.974V49.14h2.357V36.963h1.571V49.14h2.357V28.046l1.611,7.974a1.142,1.142,0,0,0,1.139.943h0a1.142,1.142,0,0,0,1.139-.943l1.611-7.974v4.282l-1.493,7.424h1.493V49.14H23.1V39.713h1.571V49.14h2.357V39.752h1.493l-1.493-7.424V28.046l1.611,7.974a1.13,1.13,0,0,0,.9.9l2.985,5.067V49.14H34.1V44.427h.786V49.14h1.571V42.305l.864,2.043a.755.755,0,0,0,.707.471.472.472,0,0,0,.314-.079A.748.748,0,0,0,38.732,43.719Z"
                transform="translate(-5.047 -17.715)"
            />
    </svg>
}

export const PatronymicIcon = () => {
    return <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 25.48 35.636">
            <g transform="translate(2.019 24.682)">
                <path d="M19.4,34.346c-5.915,0-10.5-2.514-10.5-5.915,0-1.922,1.479-3.549,4.14-4.732a2.271,2.271,0,0,1,2.809.739L19.4,28.727l3.549-4.436a2.134,2.134,0,0,1,2.809-.591H25.9c2.514,1.035,3.992,2.81,3.992,4.584C29.9,31.832,25.313,34.346,19.4,34.346Zm-5.323-9.168h-.3c-2.07.887-3.253,2.07-3.253,3.4,0,2.07,3.549,4.288,8.872,4.288s8.872-2.218,8.872-4.288c0-1.183-1.183-2.366-2.957-3.105a.145.145,0,0,1-.148-.148.908.908,0,0,0-.887.148h0l-4.732,5.915-4.732-5.767A1.351,1.351,0,0,0,14.075,25.178Z"
                      transform="translate(-8.9 -23.392)"
                />
            </g>
            <g transform="translate(2.759 2.809)">
                <path
                    d="M19.159,33.294A9.75,9.75,0,0,1,9.4,23.535V18.359a9.759,9.759,0,1,1,19.519,0v5.175A9.657,9.657,0,0,1,19.159,33.294Zm0-23.215a8.267,8.267,0,0,0-8.281,8.281v5.175a8.281,8.281,0,1,0,16.561,0V18.359A8.267,8.267,0,0,0,19.159,10.079Z"
                    transform="translate(-9.4 -8.6)"
                />
            </g>
            <g transform="translate(0 13.195)">
                <path
                    d="M10.589,22.391A2.118,2.118,0,0,1,9.11,21.8a4.5,4.5,0,0,1-1.331-1.922,4.389,4.389,0,0,1-.148-2.366A2.29,2.29,0,0,1,9.11,15.737a2.477,2.477,0,0,1,2.514.739l-1.331,1.183c-.3-.3-.591-.444-.887-.3a.792.792,0,0,0-.444.591,2.556,2.556,0,0,0,.148,1.479A3.559,3.559,0,0,0,10,20.616a.65.65,0,0,0,.739.148l.591,1.479A1.608,1.608,0,0,1,10.589,22.391Z"
                    transform="translate(-7.534 -15.623)"
                />
            </g>
            <g transform="translate(21.39 13.195)">
                <g transform="translate(0 0)">
                    <path
                        d="M22.591,22.391c-.3,0-.444,0-.591-.148l.591-1.479c.3.148.591-.148.739-.148a3.559,3.559,0,0,0,.887-1.183,2.666,2.666,0,0,0,.148-1.479.792.792,0,0,0-.444-.591c-.3-.148-.591,0-.887.3L22,16.476a2.4,2.4,0,0,1,2.514-.739,2.6,2.6,0,0,1,1.479,1.774,4.313,4.313,0,0,1-.148,2.366A3.755,3.755,0,0,1,24.514,21.8,3.972,3.972,0,0,1,22.591,22.391Z"
                        transform="translate(-22 -15.623)"
                    />
                </g>
            </g>
            <g transform="translate(15.456 14.787)">
                <path
                    d="M20.076,18.031q-.222,0-.444-.444v-.3c0-.3-.148-.591-.444-.591h0a1.339,1.339,0,0,0,.3,2.662,1.288,1.288,0,0,0,1.183-.887h0c.148-.148-.148-.444-.591-.444Z"
                    transform="translate(-17.987 -16.7)"
                />
            </g>
            <g transform="translate(6.88 14.787)">
                <path
                    d="M14.276,18.031q-.222,0-.444-.444v-.3c0-.3-.148-.591-.444-.591h0a1.339,1.339,0,0,0,.3,2.662,1.288,1.288,0,0,0,1.183-.887h0c.148-.148-.148-.444-.591-.444Z"
                    transform="translate(-12.187 -16.7)"
                />
            </g>
            <g transform="translate(2.759 0)">
                <path
                    d="M28.919,21.339l-.591-8.724A3.629,3.629,0,0,0,26.7,10.1a3.983,3.983,0,0,0-3.845-.3,3.681,3.681,0,0,0-3.7-3.105,3.358,3.358,0,0,0-2.366.887,7.2,7.2,0,0,1-3.7,1.774,4.428,4.428,0,0,0-1.479.591h0a3.629,3.629,0,0,0-1.627,2.514L9.4,21.191l.3-.739a29.759,29.759,0,0,1,3.4-7.689h0a6.584,6.584,0,0,0,.739,5.028l.444.739v-.148a6.986,6.986,0,0,1,1.774-5.767,3.829,3.829,0,0,0,6.8,0,7.517,7.517,0,0,1,1.774,5.767v.148l.444-.739a7.57,7.57,0,0,0,.739-5.028,29.759,29.759,0,0,1,3.4,7.689Z"
                    transform="translate(-9.4 -6.7)"
                />
            </g>
            <g transform="translate(4.09 13.16)">
                <g transform="translate(0 0)">
                    <path
                        d="M23.017,23.289a.654.654,0,0,1-1.035-.3,2.905,2.905,0,0,0-2.514-1.331H17.841a3.293,3.293,0,0,0-2.514,1.331h0a.78.78,0,0,1-1.035.3A9.309,9.309,0,0,1,10.3,15.6v4.584c0,5.028,3.7,9.02,8.428,9.02s8.428-3.992,8.428-9.02V15.6A9.074,9.074,0,0,1,23.017,23.289Zm-4.288,3.549a2.336,2.336,0,0,1-2.366-2.366c0-1.331,1.035-1.331,2.366-1.331s2.366.148,2.366,1.331A2.336,2.336,0,0,1,18.728,26.838Z"
                        transform="translate(-10.3 -15.6)"
                    />
                </g>
            </g>
    </svg>
}

export const DefaultIcon = () => {
    return <ErrorIcon />
}

export const SwitchIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 33 39.227">
        <g id="Page-1" transform="translate(-0.1 -0.5)">
            <g id="Core" transform="translate(0.1 0.5)">
                <g id="rotate-left" transform="translate(0)">
                    <path
                        id="Shape"
                        d="M6.326,15.566,3.421,12.66A15.376,15.376,0,0,0,.1,20.755H4.251a12.434,12.434,0,0,1,2.075-5.189Zm-2.075,9.34H.1A17.27,17.27,0,0,0,3.421,33l2.906-2.906a12.434,12.434,0,0,1-2.075-5.189Zm2.075,11a17.27,17.27,0,0,0,8.094,3.321V35.076A12.434,12.434,0,0,1,9.232,33L6.326,35.906ZM18.572,6.434V0L9.232,9.34l9.34,9.34V10.585a12.413,12.413,0,0,1,0,24.491v4.151A16.748,16.748,0,0,0,33.1,22.83a16.748,16.748,0,0,0-14.528-16.4Z"
                        transform="translate(-0.1)"
                        fill="#fff"
                    />
                </g>
            </g>
        </g>
    </svg>
}
