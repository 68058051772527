import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Slider from "@material-ui/core/Slider";

import Tooltip from "@material-ui/core/Tooltip";
import * as PropTypes from "prop-types";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const AirbnbSlider = withStyles({
    root: {
        color: '#9D0917',
        height: 3,
        padding: '13px 0',
        width: "80%",
        margin: '0 20px'
    },
    thumb: {
        height: 27,
        width: 27,
        backgroundColor: '#fff',
        border: '1px solid currentColor',
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 1,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
    valueLabel: {
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
})(Slider);

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText('#9D0917'),
        backgroundColor: '#9D0917',
        '&:hover': {
            backgroundColor: '#6e0f0f',
        },
        fontSize: '12px!important',
        height: '20px!important',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: '10px 0 0 10px'
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    wrapper1: {
        position: 'relative'
    },
    visible: {
        position: 'absolute',
        right: "-30px",
        bottom: "-20px",
        display: "flex"
    },
    hidden: {
        display: "none"
    }
}));

const PriceRange = (props) => {

    const classes = useStyles();

    const [value, setValue] = React.useState([props.rangePrice.minPrice, props.rangePrice.maxPrice]);

    const [acceptButton, setAcceptButton]  = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setAcceptButton(true)
        console.log(value[0],value[1])
    };

    function valuetext(value) {
        return `${value}°C`;
    }

    return (
        <>
        {
            props.rangePrice.maxPrice ?
                <div className={classes.wrapper1}>

                    <ColorButton variant="contained"
                                 color="primary"
                                 onClick={() => props.addPriceRange(value[0],value[1])}
                                 className={acceptButton ? classes.visible : classes.hidden}>
                        принять
                    </ColorButton>
                    <AirbnbSlider
                        value={value}
                        min={props.rangePrice.minPriceReset}
                        step={100}
                        max={props.rangePrice.maxPriceReset}
                        valueLabelDisplay="on"
                        aria-labelledby="range-slider"
                        getAriaValueText={valuetext}
                        onChange={handleChange}
                        disabled={!props.rangePrice.maxPrice}
                    />
                </div>
                : <></>
        }
        </>
    )
}

export default PriceRange;
