import React from "react";
import {Helmet} from "react-helmet";


const Delivery = () => {
    return (
        <div className="row">
            <Helmet>
                <title>Доставка | Магазин "Точка"</title>
            </Helmet>


            <h1>Доставка магазина Точка</h1>
            <p>&nbsp;</p>
            <p>Наши курьеры доставят ваш заказ в любую точку Алматы и ближайшего пригорода. Доставка по Казахстану
                производится несколькими службами и подбирается индивидуально, в зависимости от ваших пожеланий.</p>

            <p>&nbsp;</p>

            <p>&nbsp;</p>

            <p>Для получения более подробной информации звоните по телефонам: +7&nbsp;(708)&nbsp;972&nbsp;5848 и по
                телефону в Алматы +7&nbsp;(727)&nbsp;375&nbsp;6660</p>


        </div>
    )
}

export default Delivery;
