import {CatalogAPI, PagesAPI} from "../../../API/API"
import {setFilter} from "./filtersReducer";


const TOGGLE_IS_FETCHING_PRODUCT = 'TOGGLE_IS_FETCHING_PRODUCT'
const SET_PRODUCTS = 'SET_PRODUCTS'

let initialState = {
    products: [],
    categories: [],
    vendors: [],
    rangePrice: {},
    productsCount: 0,
    currentPage: 1,
    pageSize: 12,
    isFetchingProduct: false
};

const productsGridReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PRODUCTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case TOGGLE_IS_FETCHING_PRODUCT: {
            return {
                ...state,
                isFetchingProduct: action.isFetchingProduct
            };
        }
        default:
            return state
    }
};

const toggleIsFetchingProduct = (isFetchingProduct) => ({ type: TOGGLE_IS_FETCHING_PRODUCT, isFetchingProduct })
const setProducts = (productsCount,products,vendors,categories,rangePrice,currentPage) => ({ type: SET_PRODUCTS, payload: { productsCount,products,vendors,categories,rangePrice,currentPage } })


export const getProductsCatalog = (filters) => async (dispatch) => {
    dispatch(toggleIsFetchingProduct(true))
    let response = await CatalogAPI.getProductsCatalog(filters)
    if (response.data.resultCode === 0) {
        let productsCount = response.data.count
        let products = response.data.results
        let categories = response.data.categories
        let vendors = response.data.vendors
        let rangePrice = response.data.rangePrice
        dispatch(setProducts(productsCount,products,vendors,categories,rangePrice,filters.page))
    }
    dispatch(toggleIsFetchingProduct(false))
};

export const getCatalogOpt = (request) => async (dispatch) => {
    dispatch(toggleIsFetchingProduct(true))
    let response = await PagesAPI.catalogOpt(request)
    if (response.data.resultCode === 0) {
        let productsCount = response.data.count
        let products = response.data.results
        let categories = response.data.categories[0]
        let vendors = response.data.vendors
        let rangePrice = response.data.rangePrice
        dispatch(setProducts(productsCount,products,vendors,categories,rangePrice,1))
    }
    dispatch(toggleIsFetchingProduct(false))
};

export default productsGridReducer;
