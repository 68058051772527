import React from "react";
import IconButton from "@material-ui/core/IconButton";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";

const IconsButton = (props) => {

    const ColorButton = withStyles(theme => ({
        root: {
            color: props.color,
            backgroundColor: props.bgcolor,
            '&:hover': {
                backgroundColor: props.bgcolor,
            },
            border: "1px solid rgba(255,255,255,0.3)"
        },
    }))(IconButton);

    return(
        <ColorButton aria-label="cart button" component="span">
            {props.children}
        </ColorButton>
    )
}

export default IconsButton
