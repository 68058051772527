import React, {useEffect, useState} from "react";
import style from "./catalog.module.css";
import {connect} from "react-redux";
import Card from "./Card";
import {getCatalog} from "../../../../Redux/FrontReducers/CatalogReducers/catalogPageReducer";
import LinearProgress from "@material-ui/core/LinearProgress";
import {addFilter} from "../../../../Redux/FrontReducers/CatalogReducers/filtersReducer";
import ProductsGrid from "../ProductsGrid";
import {Helmet} from "react-helmet";

const Catalog = (props) => {

    let [catalog, setCatalog] = useState({show: true});
    let [productGrid, setProductGrid] = useState({show: false});

    useEffect(() => {
        props.getCatalog()
    }, [props.catalog.length])

    useEffect(() => {
        props.filters.map( filter => {
            if ((filter.name === 'search' && filter.value !== '' && filter.value !== null) || (filter.name === 'category' && filter.value !== null) || (filter.name === 'vendor' && filter.value !== null))
                return setProductGrid({show: true})
        })
    }, [props.filters])

    useEffect(() => {
        if ((document.body.clientWidth < 600) && productGrid.show) setCatalog({show: false})
    }, [productGrid])

    return (
        <div className={productGrid.show ? style.wrapperMin : style.wrapper}>

            <Helmet>
                <title>Каталог | Магазин "Точка"</title>
            </Helmet>

            {
                props.isFetchingCatalog
                    ? <LinearProgress color="secondary" className={style.gridCards} />
                    :
                    <>
                    <div className={catalog.show ? style.gridCards : style.hidden}>
                        {
                            props.catalog.length
                                ?
                                props.catalog.map((catalogElement,index) =>
                                    <Card key={index}
                                          catalogElement={catalogElement}
                                          addFilter={props.addFilter}
                                          showProductGrid={setProductGrid}
                                          productGrid={productGrid}
                                    />
                                )
                                :
                                <div>Каталоги отсутствуют</div>
                        }
                    </div>
                        {productGrid.show ? <>
                            <span className={style.expand} onClick={() => setCatalog({show: !catalog.show})}>{catalog.show ? "cвернуть" : "развернуть"} каталог</span>
                            <div className={style.srez}></div>
                        </> : <></>}
                    </>
            }


            {
                productGrid.show
                && <ProductsGrid filters={props.filters}
                                 addFilter={props.addFilter}
                />
            }


        </div>
    );
}

let mapStateToProps = (state) => {
    return {
        catalog: state.catalogPage.catalog,
        isFetchingCatalog: state.catalogPage.isFetchingCatalog,
        categories: state.productsGrid.categories,
        vendors: state.productsGrid.vendors,
        filters: state.filters.filters
    }
}

export default connect( mapStateToProps, { getCatalog, addFilter } )(Catalog);
