import React, {useEffect} from "react"
import style from "./productPage.module.css"
import {connect} from "react-redux";
import {getProduct} from "../../../../Redux/FrontReducers/CatalogReducers/productPageReducer";
import ImageBlock from "./ImageBlock";
import InfoBlock from "./InfoBlock";
import LinearProgress from "@material-ui/core/LinearProgress";
import {addProductToCart, deleteProductFromCart} from "../../../../Redux/FrontReducers/CatalogReducers/cartReducer";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import {Helmet} from "react-helmet";


const ProductPage = (props) => {

    const [realCart, setRealCart] = React.useState([]);

    useEffect(() => {
        props.getProduct(props.match.params.productId)
    }, [props.match.params.productId])

    useEffect(() => {
        setRealCart(props.cart.map(product => product.id))
    }, [props.cart])

    let price = props.product.store.filter( store => store.price === Math.min.apply(null,props.product.store.map( store => store.price)))[0]
    let productImage = props.product.images.map( (image,index) => { if (index === 0) return image.image }).join('')

    return (
        <>
            <Helmet>
                <title>{props.product.title && props.product.title+' | Магазин "Точка"'}</title>
            </Helmet>

            {
                props.isFetching
                    ? <LinearProgress color="secondary" className={style.wrapper}/>
                    :
                    <div className={style.wrapper}>
                        <div className={style.productContent}>
                            <h1 className={style.title}>{props.product.title}</h1>

                            <div className={style.productImagesBlock}>
                                <ImageBlock images={props.product.images} categoryImg={props.categories.image} />
                            </div>
                            <div className={style.productActionBlock}>
                                <span className={style.price}>{price && price.storePrice}</span>
                                <span className={style.availability}>В наличии</span>
                                <div className={style.count}>Количество</div>
                                <span className={style.code}>Код товара: {props.product.code}</span>
                                <span className={style.art}>Артикул: {props.product.article}</span>
                                {
                                    realCart.includes(props.product.id)
                                    ?
                                        <button className={style.buttonOff}
                                                onClick={() => props.deleteProductFromCart(props.product.id)}>
                                            Удалить из корзины
                                        </button>
                                    :
                                        <button className={style.button}
                                                onClick={() => props.addProductToCart(props.product.id,props.product.title,productImage)}>
                                            Заказать
                                        </button>
                                }

                                <a href={'https://wa.me/77074075730?text=Хочу заказать: http://to4ka.kz/catalog/product/'+props.product.id} target={'blank'} className={style.quick}>
                                    <div className={style.cardButtonWhatsapp}>
                                        <WhatsAppIcon /> быстрый заказ
                                    </div>
                                </a>

                                <span className={style.description}>{props.product.description}</span>
                            </div>
                            <div className={style.productInfoBlock}>
                                <InfoBlock harak={props.product.name}/>
                            </div>

                        </div>
                    </div>
            }
        </>
    )
}

let mapStateToProps = (state) => {
    return {
        product: state.productPage.product,
        categories: state.productPage.categories,
        isFetching: state.productPage.isFetching,
        cart: state.cart.products
    }
}

export default connect( mapStateToProps, { getProduct, addProductToCart, deleteProductFromCart } )(ProductPage)
