import React from "react";
import {Helmet} from "react-helmet";


const Contacts = () => {
    return (
        <div className="row">

            <Helmet>
                <title>Контакты | Магазин "Точка"</title>
            </Helmet>

            <h1>Контакты магазина Точка</h1>
            <div>
                <p>&nbsp;</p>

                <p>Магазин&nbsp;Точка - контакты:</p><br/>

                <h4>Телефоны:</h4>

                <ul>
                    <li><strong><a href="tel:+77089725848">+7&nbsp;(708)&nbsp;972&nbsp;58&nbsp;48</a></strong></li>
                    <li><strong><a href="tel:+77279725848">+7&nbsp;(727)&nbsp;972&nbsp;58&nbsp;48</a></strong></li>
                    <li><strong><a href="tel:+77273756660">+7&nbsp;(727)&nbsp;375&nbsp;66&nbsp;60</a></strong></li>
                </ul>
                <br/>
                <h4>Адрес:</h4>

                <p><strong>г. Алматы. Пр-т Абая 68/74,офис 202, угол ул. Ауэзова</strong></p>
                <br/>
                <h4>Электронная почта:</h4>

                <p><strong><a href="eml:store@to4ka.kz">store@to4ka.kz</a></strong></p>
                <br/>
                <h4>Наши операторы:</h4>

                <ul>
                    <li>Консультируют</li>
                    <li>Принимают заказы</li>
                    <li>Соединяют с курьером</li>
                    <li>Соединяют со специалистами</li>
                    <li>Совершают обратные звонки</li>
                    <li>И многое другое</li>
                </ul>
                <br/>
                <p>В случае если операторы не могут ответить на ваш звонок, вы можете оставить сообщение на
                    автоответчике. Мы вам обязательно перезвоним.</p>
            </div>



        </div>
    )
}

export default Contacts;
