import React, {useEffect} from "react";
import {connect} from "react-redux";
import style from "./cart.module.css"
import Paper from "@material-ui/core/Paper";
import OrderForm from "./OrderForm";
import {deleteProductFromCart, sendOrder} from "../../../../Redux/FrontReducers/CatalogReducers/cartReducer";
import Snackbar from "@material-ui/core/Snackbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import CategoryLinks from "../../../Objects/CategoryLinks";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const Cart = (props) => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const [cart, setCart] = React.useState(props.cart);

    useEffect(() => {
        setCart(props.cart)
    }, [props.cart])

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    console.log(cart)

    return (
        <div className={style.wrapper}>

            {
                cart.length
                ?
                    <>
                        <Paper className={style.paper}>
                            <OrderForm cart={cart} sendOrder={props.sendOrder} handleClick={handleClick}/>
                        </Paper>
                        <Paper className={style.paper}>
                            {
                                cart.map((product,index) =>
                                    <div key={index} className={style.productBlock}>
                                        <CategoryLinks productId={product.id}>
                                            <img src={product.image}/>
                                        </CategoryLinks>
                                        <CategoryLinks productId={product.id}>
                                            {product.title}
                                        </CategoryLinks>
                                        <span></span>
                                        <div>
                                            <IconButton aria-label="delete"
                                                        onClick={() => props.deleteProductFromCart(product.id)}
                                                        className={classes.margin}>
                                                <DeleteIcon fontSize="large" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            }
                        </Paper>
                    </>
                :
                    <Paper className={style.paper}>Корзина в данный момент пуста</Paper>
            }
            <div className={classes.root}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Заказ успешно отправлен!
                    </Alert>
                </Snackbar>
            </div>
        </div>
    )
}

let mapStateToProps = (state) => {
    return {
        cart: state.cart.products
    }
}

export default connect(mapStateToProps, {sendOrder,deleteProductFromCart})(Cart)
