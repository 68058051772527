import {CustomerInfoAPI} from "../../API/API";

const SET_VARIANTS = 'SET_VARIANTS';
const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const WIPE_VARIANTS = 'WIPE_VARIANTS';
const SET_DEFAULT_CHECKED = 'SET_DEFAULT_CHECKED';
const CHECKBOX_SWITCHED = 'CHECKBOX_SWITCHED';

let initialState = {
    variants: {
        valid: true,
        kindContact: []
    },
    isFetching: false
};

const autoTypeContactsReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_VARIANTS: {
            return {
                ...state,
                variants: action.variants
            };
        }
        case TOGGLE_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.isFetching
            };
        }
        case WIPE_VARIANTS: {
            return {
                ...state,
                variants: { kindContact: []}
            };
        }
        case CHECKBOX_SWITCHED: {
            return {
                ...state,
                variants: {
                        valid: state.variants.valid,
                        kindContact: state.variants.kindContact.map( kind => {
                        if (kind.componentKind === action.payload.componentKind) {
                            return {...kind, checked: action.payload.checked}
                        }
                        return kind;
                    }) }
            };
        }
        case SET_DEFAULT_CHECKED: {
            return {
                ...state,
                variants: {
                        valid: state.variants.valid,
                        kindContact: state.variants.kindContact.map( kind => {
                        if ((kind.id === 1) || (kind.id === 6) || (kind.id === 9)) {
                            return {...kind, checked: true}
                        }
                        return kind;
                    }) }
            };
        }
        default:
            return state;
    }
};

const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
const setVariants = (variants) => ({ type: SET_VARIANTS, variants });
export const wipeVariants = () => ({ type: WIPE_VARIANTS })
const setDefaultChecked = () => ({ type: SET_DEFAULT_CHECKED })
export const checkboxSwitched = (componentKind,checked) => ({ type: CHECKBOX_SWITCHED, payload: {componentKind,checked} })


export const getVariantsContactData = (inputData) => async (dispatch) => {
    dispatch(toggleIsFetching(true));
    let response = await CustomerInfoAPI.checkContactData(inputData);
    dispatch(toggleIsFetching(false));
    if (response.data.resultCode === 0) {
        const field = response.data.result.field;
        const valid = response.data.result.valid;
        const kindContact = response.data.result.kindContact;
        dispatch(setVariants(response.data.result));
        dispatch(setDefaultChecked())
    }
};


export default autoTypeContactsReducer;
