import React from "react";
import style from "./footer.module.css";

import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PinDropIcon from '@material-ui/icons/PinDrop';
import GeneralMenuItems from "../../Objects/GenerlMenuItems";
import {WhatsAppIcon} from '../../Objects/Icons/To4kaIcons'

const Footer = () => {
    return (
        <div className={style.wrapper}>
            <div className={style.footerColumn1}>

                <span>
                    <PinDropIcon />
                    г. Алматы. Абая 68/74<br/>
                    (угол Ауэзова), <br/>
                    2 этаж, офис 202
                </span>
            </div>
            <div className={style.footerColumn2}>
                <span>
                    <PhoneIcon />
                    <a href="tel:+77072174105">+7 (707) 217-41-05</a>
                </span>
                <span>
                    <PhoneIcon />
                    <a href="tel:+77089725848">+7 (708) 972-58-48</a>
                </span>
                <span>
                    <PhoneIcon />
                    <a href="tel:+77273756660">+7 (727) 375-66-60</a>
                </span>
            </div>
            <div className={style.footerColumn3}>
                <span>
                    <MailOutlineIcon />
                    <a href="mailto:store@to4ka.kz">store@to4ka.kz</a>
                </span>
                <span>
                    <WhatsAppIcon />
                    <a href="https://wa.me/77074075730">Консультация</a>
                </span>
                <span>
                    to4ka.kz
                </span>
            </div>
            <div className={style.footerColumn4}>
                <span>
                    <WatchLaterIcon />
                    График работы:
                    ПН-ПТ<br/>
                    08:00-22:00
                </span>
            </div>

        </div>
    )
}

export default Footer;
