import React from "react";
import style from "./front.module.css"
import Footer from "./Footer";
import Header from "./Header";
import Main from "./Main";
import ReactGA from 'react-ga';

const Front = () => {

    ReactGA.initialize('UA-110462420-1');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className={style.wrapper}>
            <Header className={style.header} />
            <div className={style.mainWrapper}>
                <div className={style.main}>
                    <Main />
                </div>
            </div>
            <Footer className={style.footer} />
        </div>
    );
}

export default Front;
