const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING'
const SET_FILTER = 'SET_FILTER'
const SET_SEARCH_FILTER = 'SET_SEARCH_FILTER'

let initialState = {
    filters: [
            { name: 'id', value: null },
            { name: 'code', value: null },
            { name: 'article', value: null },
            { name: 'model', value: null },
            { name: 'search', value: null },
            { name: 'category', value: null },
            { name: 'vendor', value: null },
            { name: 'excategory', value: null },
            { name: 'exvendor', value: null },
            { name: 'o', value: 'min' },
            { name: 'status', value: 'on' },
            { name: 'kind', value: null },
            { name: 'pricemin', value: 1 },
            { name: 'pricemax', value: null },
            { name: 'filter', value: null },
            { name: 'page', value: 1 },
            { name: 'limit', value: 12 },

    ],
    isFetching: false
}

const filtersReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_FILTER: {
            return {
                ...state,
                filters: state.filters.map( (filter, index) => {
                    if (filter.name === action.payload.name) {
                        return {...filter, value: action.payload.value}
                    }
                    return filter
                })
            };
        }
        case SET_SEARCH_FILTER: {
            return {
                ...state,
                filters: [
                    { name: 'id', value: null },
                    { name: 'code', value: null },
                    { name: 'article', value: null },
                    { name: 'model', value: null },
                    { name: 'search', value: action.value },
                    { name: 'category', value: null },
                    { name: 'vendor', value: null },
                    { name: 'excategory', value: null },
                    { name: 'exvendor', value: null },
                    { name: 'o', value: 'min' },
                    { name: 'status', value: 'on' },
                    { name: 'kind', value: null },
                    { name: 'pricemin', value: 1 },
                    { name: 'pricemax', value: null },
                    { name: 'filter', value: null },
                    { name: 'page', value: 1 },
                    { name: 'limit', value: 12 },

                ],
            };
        }
        case TOGGLE_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.isFetching
            };
        }
        default:
            return state
    }
}

export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching })
export const setFilter = (name,value) => ({ type: SET_FILTER, payload: {name, value} })
const setSearchFilter = (value) => ({ type: SET_SEARCH_FILTER, value })

export const addFilter = (name,value) => async (dispatch) => {
    dispatch(toggleIsFetching(true))
    dispatch(setFilter(name,value))
    dispatch(toggleIsFetching(false))
}

export const searchFilter = (name,value) => async (dispatch) => {
    dispatch(toggleIsFetching(true))
    dispatch(setSearchFilter(value))
    dispatch(toggleIsFetching(false))
}

export default filtersReducer
