export const required = (value) => {
    if (value) return undefined;
    return 'Поле обязательно для ввода';
};


export const maxLengthCreator = (maxLength) => (value) => {
    if (value.length > maxLength) return `Превышена длинна строки ${maxLength}`;
    return undefined;
};

export const emailValid = (value) => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) return 'Не верный формат E-Mail';
    return undefined;
};
