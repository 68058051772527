import React from "react";
import {Helmet} from "react-helmet";


const Tender = () => {
    return (
        <div className="row">
            <Helmet>
                <title>Предложения для тендера | Магазин "Точка"</title>
            </Helmet>


            <div className="col-sm-12 info-content" id="content">
                <p>Магазин «Точка» предлагает комплекс услуг для участника тендера:</p>
                <br/>
                <ul>
                    <li><a href="#analiz">Анализ технической спецификации</a></li>
                    <li><a href="#podbor">Подбор оборудования, соответствующего тех. спецификации</a></li>
                    <li><a href="#konsult">Консультация и переговоры с конечным заказчиком</a></li>
                    <li><a href="#tehspec">Разработка технической спецификации для ваших задач</a></li>
                    <li><a href="#dop">Дополнительные услуги согласно условиям тендера</a></li>
                </ul>
                <br/>
                <p><strong>Звоните по телефону:</strong></p>

                <p><strong><a href="tel:+77089725848"><img
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12"
                    src="http://to4ka.kz/static/img/telephone_but.svg" alt="+7&nbsp;(707)&nbsp;217&nbsp;41&nbsp;05"/></a></strong></p>
                <br/>
                <section name="analiz"><h2>Анализ технической спецификации</h2></section>

                <p>Наши специалисты тщательно изучат техническую спецификацию, приложенную к тендерной документации и
                    чётко
                    определят характеристики оборудования необходимого к поставке.</p>
                <br/>
                <section name="podbor"><h2>Подбор оборудования</h2></section>

                <p>Технические характеристики могут соответствовать конкретной модели, а могут подходить к целому ряду
                    оборудования. Наша задача определить какая модель соответствует данным характеристикам, и подобрать
                    самую оптимальную в случае выбора из модельного ряда.<br/>
                        *Характеристики противоречащие друг другу (например ЦПУ не подходит к мат. плате), выявляются и
                        предоставляются заказчику в виде экспертного заключения.</p>
                <br/>
                <section name="konsult"><h2>Консультация и переговоры с конечным заказчиком</h2></section>

                <p>Выступая от имени клиента, мы проводим консультации на уровне конечного покупателя, вступаем в
                    переговоры
                    и заключаем предварительные договорённости о соответствии поставляемого оборудования требованиям
                    заказчика.</p>
                <br/>
                <section name="tehspec"><h2>Разработка технической спецификации для ваших задач</h2></section>

                <p>В случае если вы планируете проведение тендера и хотите, чтобы ваша техническая спецификация
                    трактовалась
                    однозначно всеми подрядчиками и не допускала разночтения в характеристиках критических для вас
                    элементов, наша команда подготовит техническую спецификацию в соответствии с вашими
                    требованиями.</p>
                <br/>
                <section name="dop"><h2>Дополнительные услуги согласно условиям тендера</h2></section>

                <p>Помимо поставки оборудования и программного обеспечения, мы осуществляем монтаж, установку и
                    настройку
                    оборудования и ПО, а так же разработку технического задания и его реализацию.<br/>
                        В случае если условия тендера требуют поставки товаров или услуг, выходящих за рамки компетенции
                        клиента
                        и нашей компании, мы поможем в поиске надёжных субподрядчиков.</p>
                &nbsp;<br/>

                <h4><strong>Подробности по телефонам:</strong></h4>

                <p><strong><a href="tel:+77089725848"><img
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12"
                    src="http://to4ka.kz/static/img/telephone_but.svg" alt="+7&nbsp;(707)&nbsp;217&nbsp;41&nbsp;05"/></a></strong></p>

                <br/>
                    <p><strong>Звоните в Алматы:</strong></p>
                    <p><strong><a href="tel:+77089725848"><img
                        className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12"
                        src="http://to4ka.kz/static/img/telephone_but_2.svg" alt="+77089725848"/></a></strong></p>

            </div>
        </div>
    )
}

export default Tender;
