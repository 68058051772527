import {PagesAPI} from "../../API/API";
import {getCatalogOpt} from "./CatalogReducers/productsGridReducer";

const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING'
const LOAD_OPT = 'LOAD_OPT'

let initialState = {
    page: [],
    isFetching: false
}

const optReducer = (state = initialState, action) => {
    switch(action.type) {
        case LOAD_OPT: {
            return {
                ...state,
                page: action.page
            };
        }
        case TOGGLE_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.isFetching
            };
        }
        default:
            return state
    }
}

export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching })
export const setFilter = (page) => ({ type: LOAD_OPT, page })

export const loadOpt = () => async (dispatch) => {
    dispatch(toggleIsFetching(true))
    let response = await PagesAPI.loadOpt()
    dispatch(setFilter(response.data))
    dispatch(toggleIsFetching(false))
}

export default optReducer
