import {CatalogAPI} from "../../../API/API"


const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING'
const SET_PRODUCT = 'SET_PRODUCT'

let initialState = {
    product: {
        images: [],
        store: []
    },
    categories: {},
    isFetching: false
};

const productPageReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_PRODUCT: {
            return {
                ...state,
                ...action.payload
            };
        }
        case TOGGLE_IS_FETCHING: {
            return {
                ...state,
                isFetching: action.isFetching
            };
        }
        default:
            return state
    }
};

export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching })
const setProduct = (product,categories) => ({ type: SET_PRODUCT, payload: {product,categories} })


export const getProduct = (productId) => async (dispatch) => {
    dispatch(toggleIsFetching(true))
    let response = await CatalogAPI.getProduct(productId)
    if (response.data.resultCode === 0) {
        const product = response.data.results[0]
        const categories = response.data.categories[0]
        dispatch(setProduct(product,categories))
    }
    dispatch(toggleIsFetching(false))
};

export default productPageReducer;
